import React from "react";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import { Row, Col } from "reactstrap";
import circle from "assets/img/right-arrow-circle.png";
import smallwhite from 'assets/img/small-white.png'

const apiEndpoint = "https://skraplabbet.cdn.prismic.io/api/v2";
const accessToken = ""; // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken });

export default class SkojBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: null,
      content: null,
      link: null,
    };

    this.fetchPage();
  }

  async fetchPage() {
    const response = await Client.getByUID("content_page", "home");

    //debugger;
    if (response.data) {


      console.log("response.data", response.data)
      this.setState({
        title: response.data.skoj_title,
        content: response.data.skoj_content,
        link: response.data.skoj_link,
        image: response.data.skoj_image.url,
        linkName: response.data.skoj_link_name[0].text
      });
    } else {
      this.setState({
        title: null,
        content: null,
        link: null,
        image: null
      });
    }
  }

  render() {
    const goingOn = this.state;
    return (
      <div className="homeBoxSkoj" style={{marginTop: 30}}>
        <Row>
          <Col>
            <h3 style={{marginTop:0, marginBottom:0, color: '#000'}}>På gång i Skräpskoj</h3>
            {/* <p style={{color: '#000'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut rutrum leo non orci porta luctus facilisis eget tortor. Nullam placerat accumsan metus.</p> */}
            <hr style={{color: '#000'}}></hr>
          </Col>
        </Row>
        <Row>
          <Col><h3 className="homeTitle4"  style={{color: '#000'}}>{goingOn.title && goingOn.title[0].text}</h3></Col>
        </Row>
        <Row>
          <Col style={{color: '#000'}}>

            {RichText.render(goingOn.content)}
            
            {goingOn.image && <img src={goingOn.image} style={{
                      float: "right",   
                      width: '100%',
                      borderRadius: "20px"
                    }}></img>}

            {goingOn.link && goingOn.link.slug && (
              <Row>
                <Col>
                  <div
                    style={{
                      marginTop: "20px",
                      float: "right",
                      fontWeight: 900,
                      fontSize: "0.9em",
                    }}
                  >
                    <a href={goingOn.link.slug} style={{fontSize: 15, color: '#000'}}>
                      {goingOn.linkName}{" "}
                      <i class="fas fa-chevron-circle-right" 
                      style={{color: '#000', fontSize: "20px"
                      }}></i>    
                    </a>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
