import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input, Form, FormGroup, Card, CardBody, CardTitle, CardText, Label
} from "reactstrap";
import { connect } from "react-redux"
import Prismic from 'prismic-javascript'
import NavBarClassRoom from "components/Navbars/NavBarClassRoom"
import LandingPageClassRoom from "components/Headers/LandingPageClassRoom";
import DarkFooter from "components/Footers/DarkFooter"
import { RichText } from 'prismic-reactjs'
import sea_movie from 'assets/img/sea_movie.png'
import fish_movie from 'assets/img/fish_movie.png'
import dog_movie from 'assets/img/dog_movie.png'
import button_shark from 'assets/img/button_breakdown.png'
import button_skate from 'assets/img/kids_skrap.png'
import button_gronan from 'assets/img/button_garbage.png'
import button_bunny from 'assets/img/button_bunny.png'
import Coverflow from 'react-coverflow';
import SkrapipediaMaster from "components/SkrapipediaMaster"
import posed from 'react-pose';
import { msgChatMessageRequest } from "../store/actions";
import Animate from 'react-smooth';
import britta from 'assets/img/britta.png'
import ModalVideo from 'react-modal-video'
import Slider from "react-slick"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import NavBar from "components/Navbars/NavBar.js"
import LandingPageHeader from "components/Headers/LandingPageHeader.js"
import SkrapiBox from './SkrapiBox'

const steps = [{
    style: {
      opacity: 0,
    },
    duration: 1000,
    }, 
    {
    style: {
      opacity: 1,
    },
    duration: 1000,
  }

];

const Box = posed.div({
  hidden: { opacity: 0, height: '0px' },
  visible: { opacity: 1, height: '350px' }
})

const BoxHide = posed.div({
  hidden: { opacity: 0},
  visible: { opacity: 1 }
})

const BoxMaster = posed.div({
  hidden: { opacity: 1 },
  visible: { opacity: 0 }
})

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken })

class HomeClassRoom extends React.Component {


  constructor(props){

    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
    this.state = {
      doc: null,
      page: null,
      selectedLessons : null,
      itemsInFolder: 0,
      selectedExpert: 0,
      isVisible: true,
      textToBeSent: "",
      isOpen: false,
      videos: []
    
    }


  }


  async fetchPage() {

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at('document.id', 'XkMQeRMAACIAqn7I')
    ).then(function(response) {
      if(response)
        that.setState({ page: response });
    })
  
  }

  async fetchVideos() {

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at('document.type', 'video_carousel')
    ).then(function(response) {
      if(response)
        that.setState({ videos: response });
    })
  
  }

  async getLessonsByTag(){

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at("document.tags", ['Highlight'])
    ).then(function(response) {
      that.setState({ selectedLessons: response })
    })

  }

  componentDidMount(){
    
    this.fetchPage()
    this.getLessonsByTag()
    this.fetchVideos()

    let itemsInFolder = localStorage.getItem("lessons")
    if(itemsInFolder){
      let res = itemsInFolder.split(",");
      this.state.itemsInFolder = res.length
    }
    
  }

  handleExpertSelection(levelExpert){
   
    this.setState({ selectedExpert: levelExpert})
    this.setState({ isVisible: !this.state.isVisible })
   
  }

  componentDidUpdate() {
    // window.scrollTo(0, document.body.scrollHeight);
  }

  handleChange(e) {
    this.setState({ textToBeSent: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { textToBeSent } = this.state;
    const { sendMessage } = this.props;
    if (textToBeSent.trim() === "") {
      //alert("Empty is not allowed! ");
      return;
    }
    sendMessage(textToBeSent);
    this.setState({ textToBeSent: " " });

    window.setTimeout(function() {
      var elem = document.getElementById('data');
      elem.scrollTop = elem.scrollHeight;
    }, 1000);

  }

  render(){
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            initialSlide: 0
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        }
      ]
    };
    const { messages, onProcess } = this.props;
    const { page, selectedExpert , itemsInFolder, textToBeSent, videos} = this.state
    
    if(page && videos.results){
      
      const videoContent = videos.results[0].data

      console.log("videoContent", videos.results[0].data)

      return (
        <>

          {/* <NavBarClassRoom itemsInFolder={itemsInFolder} /> */}
          <NavBar uid={'home'}/>

          <LandingPageHeader uid={'klassrummet'}/>
            
            {/* <div className="bg-britta classroom no-print" id="bg-britta">

            <Container style={{marginTop: 20}}>
              <Row>
                <Col sm="12" lg="12">
                  <div className="homeBoxGoingOn" style={{marginTop: 12}}>
                    <Row>
                        <Col>
                          <h3  style={{marginTop:0, marginBottom:0}}>Skräpipedia</h3>
                         
                          <hr></hr>
                          <p>Här i Skräpipedia har vi samlat vår senaste kunskap om nedskräpning. Kunskapen får vi från forskare och experter och för att vi hela tiden mäter skräp på olika platser. Hoppa in och läs och lär dig mer!</p>
                        </Col>
                    </Row>                        
                  </div>     
                </Col>     
              </Row>
            </Container>         
            </div> */}

            {/* <Container style={{marginTop: 20}}>
              
                <Row>                  
                  <Col>   
                    <div className="homeBoxChat" style={{marginTop: 12}}>
                      <Row>
                          <Col>                        
                          <h3 className="homeTitle5">Lilla skräprapporten</h3>  
                            <p style={{color: '#fff'}}>
                            Varje år samlar vi på Håll Sverige Rent den senast kunskapen om nedskräpningen i en rapport. Här har du möjlighet att hitta aktuell och viktig kunskap om nedskräpning. Läs vår lilla skräprapport som är skriven för dig som går i skolan.
                            </p>                          
                            <img src="https://images.prismic.io/skraplabbet/d988bb1c-7bf3-4655-937b-5f9c2818cb06_2019_Framsida.jpg?auto=compress,format" alt="" class="rounded" />
                          </Col>                       
                        </Row>     
                        <Row style={{marginTop: 20}}>
                          <Col>
  
                            <div style={{ color:'white', float: 'right', fontWeight: 900,  fontSize: 15 }}> 
                              <a href="/klassrummet" style={{color:'white'}}>Till Rapporten <i class="fas fa-chevron-circle-right" 
                      style={{color: '#000', fontSize: "15px"
                      }}></i></a>     
                            </div>                            
                          </Col>                      
                        </Row>
                    </div>
                    
            
                  </Col>
                 
                </Row>
          
            </Container>           */}


          {/* <div className="wrapper"> */}
         
             
              <div className="bg-red-top classroom  no-print" style={{backgroundColor:"white"}}>
                <Container>
                
                  <Row>
                    <Col style={{textAlign: 'center', color: "#000"}} lg="12">
                      <h2><span style={{color: "#000000"}}>Filmer</span></h2>
                    </Col>
                  </Row>

                  <Row style={{paddingBottom: 100}}>                   
                    <Col sm={12} lg={12}>
                 
                    <Slider {...settings}>

                        {
                        // "https://www.youtube.com/embed/U_ogaXMUhWY"
                        videoContent && videoContent.video.map(function(item){
              
                            return(
                            <Col sm={12} lg={12}>
                             <div> 
                               <div class="video-wrapper">
                                <iframe width="100%" height="480" src={`https://www.youtube.com/embed/${item.videoid[0].text}`}  />       
                                </div>
                             </div>       
                             </Col>  
                            )
                          }
                        )
                        }
                    </Slider>
                   
                    <div>
                                            
                  </div>
                    

                  
                    </Col>
                  </Row>
                </Container> 
                <Container>
                
                <Row>
                  <Col style={{textAlign: 'center', color: "#000", marginBottom: 40}} lg="12">
                    <h3><span style={{color: "#000000"}}><a href="/contentpage/filmer/f">Se alla filmer</a></span></h3>
                  </Col>
                </Row>             
              </Container> 
                
              </div>


              {/* <div className="bg-green classroom" name="master">
                <Container>

                  <Row>
                    <Col style={{textAlign: 'center'}} lg="12">
                      <h2>Är du Skräpipedia Master?</h2>
                    </Col>
                    <Col style={{textAlign: 'center'}} lg="12">
                      <p style={{fontSize: "20px", color: "white"}} className='no-print'>Kampen mot klockan och dina vänner startar här och nu! <br></br>Är du redo? <br></br><br></br>
                      </p>

                      <BoxHide className="box" pose={this.state.isVisible ? 'visible' : 'hidden'} >
                      <p style={{fontSize: "20px", color: "white"}}> <b style={{fontWeight: 900}}>Välj expertisområde nedan!</b></p>
                      </BoxHide>
                  
                    </Col>
                  </Row>
                  
                  <Box className="box" pose={this.state.isVisible ? 'visible' : 'hidden'} >
                    <Row>

                      <Col style={{textAlign: 'center'}} lg="4"><img src={button_gronan} alt='Staden' style={{marginBottom: "10px", cursor: 'pointer', width:200}}  onClick={() => this.handleExpertSelection(1)} /><br></br><h4>Vanligaste Skräpet</h4></Col>

                      <Col style={{textAlign: 'center'}}  lg="4"><img src={button_shark} alt='Havet' data-action="#" style={{marginBottom: "10px", cursor: 'pointer',width:200}} onClick={() => this.handleExpertSelection(2)}/><br></br><h4>Nedbrytningstider</h4></Col>

                    </Row>
                  </Box>

                  

                
                </Container>
                
                <BoxMaster className="box" pose={this.state.isVisible ? 'visible' : 'hidden'} >
                  <SkrapipediaMaster selectedExpert={selectedExpert}></SkrapipediaMaster>
                </BoxMaster>

              </div> */}

              {/* <div className="bg-britta classroom no-print" id="bg-britta">
                <Container>
                  <Row>
                    <Col lg="6">
                      <a name="bg-britta"></a>
                      
                      <h2>Fråga Britta</h2>
                      <p>Säg hej till Britta! Hon är vår skräpexpert här i Skräplabbet. Britta brinner för att stoppa nedskräpningen! Här kan du testa att ställa dina frågor till henne. Sakta men säkert kommer hon att kunna svara på ännu fler frågor. </p>     
                      
                     
                      <br/><br/>
                    </Col>
                    <Col lg="6">
                      <div style={chat} id="data" class="chat">

                      {messages.map(({ text, sender, isErrorMessage }, index) => (

                        sender === "Bot" ? <Animate steps={steps}>

                            <Row>
                              <Col xs="10" sm="10">
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col lg="3"><img src={britta} height={60}/></Col>
                                      <Col>{text}</Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xs="2" sm="2"></Col>

                            </Row>

                          </Animate> : 
                          <Animate steps={steps}>

                            <Row>

                              <Col xs="2" sm="2"></Col>
                              <Col xs="10" sm="10">
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col lg="2"><i class="gg-smile"></i></Col>
                                      <Col>Du: {text}</Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>

                          
                          </Animate>
                        
                        
                      ))}

                      </div>

                      <form onSubmit={this.handleSubmit}>
                        <Input
                          ref={input => input && input.focus()}
                          type="text"
                          value={onProcess ? "Väntar på svar..." : textToBeSent}
                          onChange={this.handleChange}
                          placeholder="Skriv Hej och tryck på enter!"
                          className="chatBox"
                          disabled={onProcess}
                        />
                      </form>
                    </Col>
                    
                  </Row>

                 
                </Container>
              </div> */}

              <div className="bg-skrapipedia classroom  no-print">
                <Container>
                  <Row>
                    <Col lg="6">
                      <h2>Skräpipedia</h2>
                      <p>
                      Här i Skräpipedia har vi samlat vår senaste kunskap om nedskräpning. Kunskapen får vi från forskare och experter och för att vi hela tiden mäter skräp på olika platser. Hoppa in och läs och lär dig mer!  
                      </p>
                      <a href="/contentpage/skrapipedia/skrapipedia" className="btn-cta-classroom">Ta mig dit</a>
                    </Col>
                    <Col lg="6">
                      <img src="https://images.prismic.io/skraplabbet/ee2f2659-7a9c-4579-984a-35d16b5ea418_books.jpg?auto=compress,format" alt="" class="rounded"/>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* <div className="bg-rapporten  no-print">
                <Container>
                  <Row>
                    
                    <Col lg="6">
                      <img src="https://images.prismic.io/skraplabbet/d988bb1c-7bf3-4655-937b-5f9c2818cb06_2019_Framsida.jpg?auto=compress,format" alt="" class="rounded"/>
                    </Col>
                    <Col lg="6">
                      <h2 className="blackText headline">Lilla skräprapporten</h2>
                      <p className="blackText">
                      Varje år samlar vi på Håll Sverige Rent den senast kunskapen om nedskräpningen i en rapport. Här har du möjlighet att hitta aktuell och viktig kunskap om nedskräpning. Läs vår lilla skräprapport som är skriven för dig som går i skolan.
                      </p>
                      <a href="/contentpage/lilla-skraprapporten-2020/Skräprapporten%202020" className="btn-cta-classroom">Läs Lilla skräprapporten</a>
                    </Col>
                  </Row>
                </Container>
              </div>  */}

              {/* <div className="bg-inspiration">
                <Container>
                  <Row>
                    <Col lg="6">
                      <h2 className="whiteText headline">Vad kan du göra?</h2>
                     
                     <h4 className="whiteText">Massor!</h4>
                     <p className="whiteText ">
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id elit at nisi viverra tempus. Sed vehicula ut dui non condimentum. Donec ut dictum est. Nulla ut metus id dolor tincidunt iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ac neque pulvinar, consequat nulla non, consectetur nulla. 
                      </p>
                      <a href="/contentpage/inspiration/inspiration" className="btn-cta-classroom">Ta mig till inspiration</a>
                    </Col>
                    <Col lg="6">
                    <img src="https://images.prismic.io/skraplabbet/ee3ccf46-1a9e-4b65-a7fa-cc0e2b2495a8_change.png?auto=compress,format" alt="" class="rounded"/>
                    </Col>
                    
                  </Row>
                </Container>
              </div> */}

             <DarkFooter />
          {/* </div> */}
        </>
      )

    }
    else{
      return null
    }
  }

  
}

const chat ={
  height: '300px',
  overflowX: 'hidden'

}
const headingStyle = {
 
}
const listStyle = {
 
};
const formPosStyle = {
  backgroundColor: "white"
};


const mapStateToProps = state => state;
const mapActionToProps = { sendMessage: msgChatMessageRequest };

export default connect(mapStateToProps, mapActionToProps)(HomeClassRoom);

// export default HomeClassRoom;
