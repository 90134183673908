import React from "react";
import {Container, Row, Col, Card, CardBody, Input } from "reactstrap";

import Prismic from 'prismic-javascript'
import NavBar from "components/Navbars/NavBar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter"

import brittaorange from 'assets/img/brittaorange.png' 
import brittayellow from 'assets/img/brittayellow.png' 

import GoingOnBox from './GoingOnBoxSkoj'
import Knep from './KnepKnop'

import Fun from './Fun'

import { UnityContext } from "react-unity-webgl";
import Animate from 'react-smooth'
import britta from 'assets/img/britta.png'
import { msgChatMessageRequest } from "../storeFun/actions";
import { connect } from "react-redux"

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken })


const steps = [{
  style: {
    opacity: 0,
  },
  duration: 1000,
  }, 
  {
  style: {
    opacity: 1,
  },
  duration: 1000,
}

];

class SkrapSkoj extends React.Component {

  constructor(props){

    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
    this.state = {
      doc: null,
      page: null,
      itemsInFolder: 0,
      textToBeSent: ""
    }
    
    this.fetchPage()
    this.getLessonsByTag()

    this.unityContext = new UnityContext({
      loaderUrl: "games/build/myunityapp.loader.js",
      dataUrl: "build/myunityapp.data",
      frameworkUrl: "build/myunityapp.framework.js",
      codeUrl: "build/myunityapp.wasm"
    });
    
  }

  handleChange(e) {
    this.setState({ textToBeSent: e.target.value });
  }
  handleSubmit(e) {
    e.preventDefault();
    const { textToBeSent } = this.state;
    const { sendMessage } = this.props;
    if (textToBeSent.trim() === "") {
      //alert("Empty is not allowed! ");
      return;
    }
    sendMessage(textToBeSent);
    this.setState({ textToBeSent: " " });

    window.setTimeout(function() {
      var elem = document.getElementById('data');
      elem.scrollTop = elem.scrollHeight;
    }, 1000);

  }
  async getLessonsByTag(){

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at("document.tags", ['Highlight'])
    ).then(function(response) {
      that.setState({ selectedLessons: response })
    })

  }

  async fetchPage() {

    let that = this
    const response = await Client.getByUID('content_page', 'skrapskoj').then(function(document) {
      if (document) {
        that.setState({ page: document });
      } else {
        that.setState({ page: !document });
      }
    })
  
  }

  render(){

 

    const { messages, onProcess } = this.props
    const { page, textToBeSent } = this.state

    let pageContent = null

    if(page){

      pageContent = page

      console.log("pageContent", pageContent)

      return (
        <>
          <NavBar uid={'skrapskoj'}/>
    
          <div className="wrapper">
         
              <LandingPageHeader uid={'skrapskoj'}/>

              <Container>
                <Row>
                  <Col sm="12" lg="6">
                    <h2 style={{marginBottom: 20, marginTop:30}}> <img src={brittaorange} style={{marginRight: "10px", width: 60, height: 60}}></img>Låt oss ha kul i skräpskoj...</h2>
                  </Col>
                </Row>
              </Container>


              <Container>
                <Row>
                  <Col sm="12" lg="6">

                  

                    <GoingOnBox />
                    <Knep />  

                  </Col>
                  <Col>     

               
                  <div className="homeBoxChat" style={{marginTop: 12, backgroundColor: "#ff6600"}}>
                    {
                    pageContent.data.britta_title[0].text && <>
                    <Row>
                        <Col>                        
                        <h3 className="homeTitle5">{pageContent.data.britta_title[0].text}</h3>  
                          <p style={{color: '#fff'}}>
                          {pageContent.data.britta_content[0].text}
                          </p>                          
                        </Col>
                        <Col style={{maxWidth: 100}}>
                        <img src={brittayellow} style={{float: 'right'}}></img>
                      </Col>        
                      </Row>     
                      <Row>
                        <Col>
                          <div style={{ color:'white', float: 'right', fontWeight: 900,  fontSize: 15 }}> 
                            <a href={"/innehall/"+ pageContent.data.britta_link.uid } style={{ color:'white', float: 'right', fontWeight: 900,  fontSize: 15 }}>Till Britta bjuder in <i class="fas fa-chevron-circle-right" 
                    style={{color: '#000', fontSize: "15px"
                    }}></i>    </a> 
                          </div>                            
                        </Col>                      
                      </Row>
                    </>}

                    
                  </div>
                  
                  <Fun />  


              


                  </Col>
                 
                </Row>
          
                <br></br><br></br><br></br><br></br>

              </Container>             
        
             <DarkFooter/>

          </div>
        </>
      )

    }
    else{
      return null
    }
  }

  
}

const chat ={
  height: '500px',
  overflowX: 'hidden'

}
const headingStyle = {
 
}
const listStyle = {
 
};
const formPosStyle = {
  backgroundColor: "white"
};


const mapStateToProps = state => state;
const mapActionToProps = { sendMessage: msgChatMessageRequest };

export default connect(mapStateToProps, mapActionToProps)(SkrapSkoj);
