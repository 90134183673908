import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input, CardColumns, InputGroupAddon, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Form, FormGroup, Label 
} from "reactstrap";
import { RichText } from 'prismic-reactjs'
import Prismic from 'prismic-javascript'
import NavBar from "components/Navbars/NavBar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter"
import Lesson from "components/Lesson"
import FadeIn from 'react-fade-in'
import ReactLoading from 'react-loading'
import { ScrollTo } from "react-scroll-to"
import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';
import firebase from "./FirebaseConfig";
import uuid from 'react-uuid'
import slugify from 'react-slugify'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import LessonSearchByTag from "./LessonSearchByTag"

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
 
const Client = Prismic.client(apiEndpoint, { accessToken })

class Lessons extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      lessonId: null,
      itemsInFolder: 0,
      lessonsList:  localStorage.getItem("lessons"),
      lessonsContent: null,
      packageName: "",
      created: false, 
      newPackage: null,
      uniqueId: null,
      value: '',
      copied: false,

    }
    
    this.fetchPage()
    this.handleItemClick = this.handleItemClick.bind(this)    

  }

  async fetchPage() {

    let that = this;
    const response = await Client.getByUID('content_page', 'lessons').then(function(document) {
      if (document) {
        that.setState({ page: document });
      } else {
        that.setState({ notFound: !document });
      }
    })

  }

  handleItemClick(event, id){
    this.setState({ lessonId: id })
  }

  componentDidMount(){  

    if(this.props.match.params.lessonId){
      this.setState(() => ({ lessonId: this.props.match.params.lessonId }))
    }

    let itemsInFolder = localStorage.getItem("lessons")
    if(itemsInFolder){
      let res = itemsInFolder.split(",");
      this.state.itemsInFolder = res.length
    }
    this.getLessonsById()

  }

  async getLessonsById(){
    let that = this
    if(localStorage.getItem("lessons")){
      let lessons = localStorage.getItem("lessons")
      var lessonIds = JSON.parse(lessons)
      Client.getByIDs(lessonIds).then(function(response) {
        that.setState({ lessonsContent: response })
      })
    }
    else{
      that.setState({ lessonsContent: null })
    }
  }
  
  removeFromMyLessons(event, id){

    this.setState({ itemsInFolder: this.state.itemsInFolder - 1 })
    
    let lessons = localStorage.getItem("lessons")
    var lessonIds = JSON.parse(lessons)
    const filteredItems = lessonIds.filter(item => item !== id)
    localStorage.setItem('lessons', JSON.stringify(filteredItems))

    if(this.state.itemsInFolder - 1 == 0){
      this.setState({ itemsInFolder: 0 })
      localStorage.removeItem('lessons')
    }

    toast.notify("Lektion borttagen");

    this.getLessonsById()
  }

  savePackage(){

    let packageSlug = slugify(this.state.packageName);
    let lessons = localStorage.getItem("lessons")
    var lessonIds = JSON.parse(lessons)

    this.state.license = null
    this.state.page = null
    this.state.page = null
    this.state.results = null
    this.state.lessonsContent = null
    this.state.created = null

    this.state.lessonsList = lessonIds
    this.state.packageSlug = packageSlug

    let id = uuid()
 
    firebase.database().ref("/lessons/"+ id).set(this.state)
    
    localStorage.removeItem('lessons')

    let packages = localStorage.getItem("packages")
    if(packages){
      var packagesIds = JSON.parse(packages)
      packagesIds.push(id)
      localStorage.setItem('packages', JSON.stringify(packagesIds))
    }
    else{
      var packagesIds = []
      packagesIds.push(id)
      localStorage.setItem('packages', JSON.stringify(packagesIds))
    }

    let packagesName = localStorage.getItem("packagesName")
    if(packagesName){
      var packagesNames = JSON.parse(packagesName)
      packagesNames.push(this.state.packageName)
      localStorage.setItem('packagesName', JSON.stringify(packagesNames))
    }
    else{
      var packagesNames = []
      packagesNames.push(this.state.packageName)
      localStorage.setItem('packagesName', JSON.stringify(packagesNames))
    }


    localStorage.setItem(id, JSON.stringify(lessonIds))
   

    this.setState({ created: true })
    this.setState({ uniqueId: id })

    this.fetchPage()
    this.getPackageData(id)
  }

  getPackageData = (id) => {
    let ref = firebase.database().ref("/lessons/"+ id);
    ref.on("value", snapshot => {
      const state = snapshot.val();
      console.log("snapshot", state)
      this.setState({newPackage: state });
    });
  };

  handleChange = event => {
    const value = event.target.value;
    this.setState({
      packageName: value
    })
  }

  render(){

    const { page, lessonId, itemsInFolder, lessonsContent, created, uniqueId } = this.state
    
    if(page){

      let that = this
      const navigation = page.data.body.map(function(slice, index) {
        return (
          <div key={index} className="menuTopic">
                {slice.primary.category_title[0].text}
              
                {slice.items.map(function(item, index){
                  return (<Row className="menuLesson"><Col lg="1"><span class="gg-file-document"></span></Col><Col><a href="javascript:void()" onClick={(event) => that.handleItemClick(event, item.lesson.id)}> {item.lesson.tags[0]}</a></Col></Row>)
                })}
              
          </div>
        )
      })

     let uniqueUrl = "https://skraplabbet.se/me/"+ uniqueId

      return (
        <>

          <NavBar lessonId={lessonId} itemsInFolder={itemsInFolder} addLessonToFolder={this.addLessonToFolder}/>
    
          <div className="wrapper">
              <FadeIn>
                <LandingPageHeader uid={'lessons'}/>
              </FadeIn>
              <Container className="bg-white first-row">
                
                {created === true && <>
               
                <Row>
                  <Col lg="6">
                    <h2>Klart!</h2>
                    <p>
                      Nu har vi sparat din lektionsplanering.<br></br>
                      Här till vänster i rutan är den unika länken för din lektionsplanering.
                    </p>
                    <p>
                      Du kan även nå dina lektionsplaneringar på denna enhet, genom länken i topp-menyn.
                    </p>

                    <Button color="primary" href={'/teacher'}><i class="gg-chevron-double-left"></i> Sätt ihop ett nytt lektionsplanering</Button> 
                    
                  </Col>
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <CardTitle><h3>Lektionsplaneringens unika länk</h3></CardTitle>
                        <CardText> 
                        
 
                          <small>
                            <center>
                          <CopyToClipboard text={uniqueUrl}
                            onCopy={() => this.setState({copied: true})}>
                            <span>https://skraplabbet.se/me/{uniqueId}</span>
                          </CopyToClipboard>
                          <br></br>
                           </center>
                          </small>

                          {this.state.copied ? <p style={{fontSize: "14px", color:"green"}}><center>Länken kopierad. Nu kan du klistra in den, spara, maila eller surfa direkt till den.</center></p> : null}

                        </CardText>

                        <CopyToClipboard text={uniqueUrl}
                            onCopy={() => this.setState({copied: true})}>
                          <Button color="primary" href={''}className="pull-right"><i class="gg-copy"></i> Kopiera länken</Button> 
                        </CopyToClipboard>

                        <Button color="primary" href={''} className="pull-right"><i class="gg-mail"></i> Skicka till min email</Button> 
                       
                      </CardBody>
                    </Card>
                   
                  </Col>
                </Row>

                </>
                }
                

                {!created && lessonsContent === null &&
                  <Row>
                    <Col>
                    <p>Du har inga valda övningar.</p>
                    <p>Välj ett ämnesområden nedanför och plocka ihop dina övningar.</p>
                    </Col>
                  </Row>
                }

                {created === false && lessonsContent !== null && <>
                <Row>
                  <Col className="ml-auto mr-auto" name="lessonContent" md="12">
                   <h2>Skapa lektionsplanering</h2>
                  </Col>
                </Row>
                
                <Row>
                  <Col lg="6">
                    <p>Följande övningar har du valt och kommer att ingå i din lektionsplanering. Välj ett passande namn och sedan klicka på "Spara min lektionsplanering"</p>
                  </Col>
                  <Col lg="6">
                   <Form>
                      <FormGroup>
                        <Label for="exampleEmail">Namge din lektionsplanering och spara</Label>
                        <Input type="text" value={this.state.packageName}  placeholder="Exempel på namn: För klassen 6A" onChange={this.handleChange} />
                      </FormGroup>
                      <Button color="success" onClick={(event) => this.savePackage()} className=""><i class="gg-file-add"></i> Spara min lektionsplanering</Button>
                    </Form>
                  </Col>
                </Row>
                <hr></hr>
                <Row><Col md="12">
                  <CardColumns>
                    {lessonsContent 
                      && lessonsContent.results.map(function(item, index)
                      {

                      let naviCat
                      if(item.data.category==="hav"){
                        naviCat = "sea"
                      }

                      if(item.data.category==="natur"){
                        naviCat = "nature"
                      }

                      if(item.data.category==="manniska"){
                        naviCat = "human"
                      }

                      if(item.data.category==="stad"){
                        naviCat = "city"
                      }

                      return (
                        
                          <Card>
                            <img class="card-img-top" src={item.data.lesson_image.url} alt=""></img>
                            <CardBody>
                              <CardTitle><h3>{item.data.title[0] && item.data.title[0].text}</h3></CardTitle>
                              <CardText>{item.data.intro[0] && item.data.intro[0].text}</CardText>
                              <Button color="secondary" onClick={(event) => that.removeFromMyLessons(event, item.id)}><i class="gg-trash"></i> Ta bort</Button>
                              <Button color="primary" href={'/topic/'+ item.id} className="pull-right"><i class="gg-readme"></i> Läs mer</Button>
                            </CardBody>
                          </Card>
                       
                        )
                      })
                    }

                    {lessonsContent === null &&
                      <Col><p>Du har inga valda övningar...</p></Col>
                    }
                  </CardColumns></Col>
                </Row></>}

                <LessonSearchByTag />
                
              </Container>



              <DarkFooter />
          </div>
        </>
      )

    }
    else{
      return <ReactLoading type={"balls"} color={"#007F9C"} height={'50px'} width={'50px'} />
    }
  }

  
}

export default Lessons;
