import React from "react";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import { Row, Col } from "reactstrap";
import circle from "assets/img/right-arrow-circle.png";

const apiEndpoint = "https://skraplabbet.cdn.prismic.io/api/v2";
const accessToken = ""; // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken });

export default class GoingOnBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: null,
      content: null,
      link: null,
    };

    this.fetchPage();
  }

  async fetchPage() {
    const response = await Client.getByUID("content_page", "skrapskoj");

    //debugger;
    if (response.data) {
      this.setState({
        title: response.data.knep_title,
        content: response.data.knep_content,
        link: response.data.knep_link,
        image: response.data.knep_image.url,
      });
    } else {
      this.setState({
        title: null,
        content: null,
        link: null,
        image:null
      });
    }
  }

  render() {
    const goingOn = this.state;
    return (
      <div className="knep" style={{marginTop: 12}}>
        <Row>
          <Col>
            <h3  style={{marginTop:0, marginBottom:0}}>🤔 Knep &amp; Knåp</h3>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut rutrum leo non orci porta luctus facilisis eget tortor. Nullam placerat accumsan metus.</p> */}
           
            <hr></hr>
          </Col>
        </Row>
        <Row>
          <Col><h3 className="homeTitle4">{goingOn.title && goingOn.title[0].text}</h3></Col>
        </Row>
        <Row>
          <Col>
            {RichText.render(goingOn.content)}

            {goingOn.image && <img src={goingOn.image} style={{
                      float: "right",   
                      width: '100%',
                      borderRadius: "20px"
                    }}></img>}
            
            
              <Row>
                <Col>
                  <div
                    style={{
                      marginTop: "15px",
                      float: "right",
                      fontWeight: 900,
                      fontSize: "0.9em",
                    }}
                  >
                    <a href={"/innehall/knepochknap"} style={{fontSize: 15, color: '#000'}}>
                      Till Knep & Knåp{" "}
                      <i class="fas fa-chevron-circle-right" 
                      style={{color: '#000', fontSize: "15px"
                      }}></i>    
                    </a>
                  </div>
                </Col>
              </Row>
            
          </Col>
        </Row>
      </div>
    );
  }
}
