import React from "react";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import { Row, Col } from "reactstrap";
import circle from "assets/img/right-arrow-circle.png";

const apiEndpoint = "https://skraplabbet.cdn.prismic.io/api/v2";
const accessToken = ""; // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken });

const linkResolver = (doc) => {
  // Pretty URLs for known types
  if (doc.type === 'blog') return `/${doc.uid}`
  if (doc.type === 'page') return `/${doc.uid}`
  // Fallback for other types, in case new custom types get created
  return `/${doc.uid}`
}

export default class GoingOnBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: null,
      content: null,
      link: null,
    };

    this.fetchPage();
  }

  async fetchPage() {
    const response = await Client.getByUID("content_page", "home");
    //console.log("response.dataresponse.dataresponse.dataresponse.dataresponse.data", response.data)
    //debugger;
    if (response.data) {
      this.setState({
        title: response.data.happening_title,
        content: response.data.happening_content,
        link: response.data.happening_link,
        image: response.data.happening_image.url,
        linkName: response.data.happening_link_name[0].text,
        body: response.data.body,
        body1: response.data.body1
      });
    } else {
      this.setState({
        title: null,
        content: null,
        link: null,
        image:null,
        linkName: null,
        body: null,
        body1: null
      });
    }
  }

  render() {
    const goingOn = this.state;
    
    console.log("goingOn", goingOn.body1 && goingOn.body1)

    return (
      <div className="homeBoxGoingOn" style={{marginTop: 12}}>
        <Row>
          <Col>
            <h3  style={{marginTop:0, marginBottom:0}}>På gång just nu</h3>            
           
            <hr></hr>
          </Col>
        </Row>
        <Row>
          <Col><h3 className="homeTitle4">{goingOn.title && goingOn.title[0].text}</h3></Col>
        </Row>
        <Row>
          <Col>
          
          {goingOn.image && <img src={goingOn.image} style={{
                      float: "left",   
                      marginRight: '10px',
                      height: '150px',
                      borderRadius: "10px"
                    }}></img>}
            {RichText.render(goingOn.content)}

            
            
            {goingOn.link && goingOn.link.url && (
              <Row>
                <Col>
                  <div
                    style={{
                      marginTop: "15px",
                      float: "right",
                      fontWeight: 900,
                      fontSize: "0.9em",
                    }}
                  >
                    <a href={goingOn.link.url} style={{fontSize: 15, color: '#000'}}>
                      {goingOn.linkName}{" "}
                      <i class="fas fa-chevron-circle-right" 
                      style={{color: '#000', fontSize: "15px"
                      }}></i>    
                    </a>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {
          goingOn.body1 && RichText.render(goingOn?.body1[0]?.items[0]?.primary)          
        }
      </div>
    );
  }
}
