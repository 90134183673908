import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

import Prismic from "prismic-javascript";
import NavBar from "components/Navbars/NavBar.js";
import LandingPageHeaderTeacher from "components/Headers/LandingPageHeaderTeacher.js";
import DarkFooter from "components/Footers/DarkFooter";
import { RichText } from "prismic-reactjs";
import LessonSearchByTagV2 from "./LessonSearchByTag";
import LessonSearchByText from "./LessonSearchByText";

const apiEndpoint = "https://skraplabbet.cdn.prismic.io/api/v2";
const accessToken = ""; // This is where you would add your access token for a Private repository

const Client = Prismic.client(apiEndpoint, { accessToken });

class HomeTeacher extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      doc: null,
      page: null,
      selectedLessons: null,
      itemsInFolder: 0,
      preselect: false
    }

    console.log("preselect", this.props.preselect)


  }

  async fetchPage() {
    let that = this;
    const r = await Client.query(
      Prismic.Predicates.at("document.id", "XjGY8xAAACIAct4C")
    ).then(function (response) {
      if (response) that.setState({ page: response });
      //console.log("setState==> ", that.state);
    })


  }

  async getLessonsByTag() {
    let that = this;
    const r = await Client.query(
      Prismic.Predicates.at("document.tags", ["Highlight"])
    ).then(function (response) {
      that.setState({ selectedLessons: response });
    });
  }

  componentDidMount() {
    this.fetchPage();
    this.getLessonsByTag();

    let itemsInFolder = localStorage.getItem("lessons");
    if (itemsInFolder) {
      let res = itemsInFolder.split(",");
      this.state.itemsInFolder = res.length;
    }


    if(this.props.preselect){
      this.setState({preselect:true})
    }

  }

  render() {
    const { page, selectedLessons, itemsInFolder } = this.state;

    if (page) {
      let pageContent = page.results[0];

      return (
        <>
          <NavBar itemsInFolder={itemsInFolder} />

          <div className="wrapper">
            <LandingPageHeaderTeacher />

            <Container className="bg-white first-row">
              <Row>
                <Col className="" lg="8">
                  {RichText.render(pageContent.data.welcome)}
                  {RichText.render(pageContent.data.welcome_content)}
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <h3 style={{ marginBottom: -20 }}>Bläddra genom kategori</h3>
                </Col>               
                <Col className="ml-auto mr-auto" md="12">
                    <Row>
                      <Col md="3"><a href="/topic/human"><div className="navi human v2">MÄNNISKA</div></a></Col>
                      <Col md="3"><a href="/topic/city"><div className="navi city v2">STAD</div></a></Col>
                      <Col md="3"><a href="/topic/sea"><div className="navi sea v2">HAV</div></a></Col>
                      <Col md="3"><a href="/topic/nature"><div className="navi nature v2">NATUR</div></a></Col>
                    </Row>
                  </Col>
              </Row> */}
            </Container>

           
            <LessonSearchByTagV2 preselect={this.state.preselect}/>

            <div className="bg-grey">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" md="12">
                    {RichText.render(pageContent.data.inspiration_title)}
                    {RichText.render(pageContent.data.inspiration_content)}
                  </Col>
                </Row>

                <Row>
                  {selectedLessons &&
                    selectedLessons.results.map(function (item, index) {
                      let naviCat;
                      if (item.data.category === "hav") {
                        naviCat = "sea";
                      }

                      if (item.data.category === "natur") {
                        naviCat = "nature";
                      }

                      if (item.data.category === "manniska") {
                        naviCat = "human";
                      }

                      if (item.data.category === "stad") {
                        naviCat = "city";
                      }

                      let link = "";
                      if (item.type === "highlight") {
                        link = "/me/" + item.data.id[0].text;
                      } else {
                        link = "/topic/" + item.id;
                      }

                      return (
                        <Col
                          className="ml-auto mr-auto"
                          name="lessonContent"
                          md="4"
                        >
                          <Card>
                            <img
                              class="card-img-top"
                              src={item.data.lesson_image.url}
                              alt=""
                            ></img>
                            <CardBody>
                              <CardTitle>
                                <h4>{item.data.title[0].text}</h4>
                                <i class="gg-time"></i> Uppskattad tid är 
                                {item.data.aproximate_time} minuter
                              </CardTitle>
                              <CardText>
                                {item.data.intro[0].text}
                                <br></br>
                              </CardText>

                              {/* <a
                                
                                href={link}
                                className="pull-right"
                              >
                                <i class="gg-chevron-double-right"></i>Läs mer
                              </a> */}

                              <div
                              style={{
                                marginTop: "15px",
                                float: "right",
                                fontWeight: 900,
                                fontSize: "0.9em",
                                marginBottom: 10
                              }}
                            >   
                              <a href={link} style={{fontSize: 15, color: '#000'}}>
                                Läs mer{" "}
                                <i class="fas fa-chevron-circle-right" 
                                style={{color: '#000', fontSize: "15px"
                                }}></i>    
                              </a>
                            </div>

                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </Container>
            </div>
            
            <LessonSearchByText />

            <DarkFooter />
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

export default HomeTeacher;
