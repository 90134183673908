import { createStore, applyMiddleware } from "redux";
import ChatMessageReducerFun from "./reducers";
import chatbot3 from "../middlewares/chat-bot-fun";

const logger = () => next => action => {
  console.log("[logger]: ", action.type);
  next(action);
};

export default createStore(
  ChatMessageReducerFun,
  applyMiddleware(logger, chatbot3)
);