import React from "react";
import {
  Container,
  Row,
  Col} from "reactstrap";
import { connect } from "react-redux"
import Prismic from 'prismic-javascript'
import NavBarClassRoom from "components/Navbars/NavBarClassRoom"
import DarkFooter from "components/Footers/DarkFooter"
import { msgChatMessageRequest } from "../store/actions";
import "react-responsive-carousel/lib/styles/carousel.min.css";
 import { ReactTypeformEmbed } from 'react-typeform-embed';

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository

class Contest extends React.Component {


  constructor(props){

    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
    this.state = {
      doc: null,
      page: null,
      selectedLessons : null,
      itemsInFolder: 0,
      selectedExpert: 0,
      isVisible: true,
      textToBeSent: "",
      isOpen: false,
      videos: []
    
    }


  }


  async fetchPage() {

  
  }

  async fetchVideos() {

  
  }

  async getLessonsByTag(){


  }

  componentDidMount(){
    
    this.fetchPage()
    this.getLessonsByTag()
    this.fetchVideos()

    let itemsInFolder = localStorage.getItem("lessons")
    if(itemsInFolder){
      let res = itemsInFolder.split(",");
      this.state.itemsInFolder = res.length
    }
    
  }

  handleExpertSelection(levelExpert){
   
    this.setState({ selectedExpert: levelExpert})
    this.setState({ isVisible: !this.state.isVisible })
   
  }

  componentDidUpdate() {
    // window.scrollTo(0, document.body.scrollHeight);
  }

  handleChange(e) {
    this.setState({ textToBeSent: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { textToBeSent } = this.state;
    const { sendMessage } = this.props;
    if (textToBeSent.trim() === "") {
      //alert("Empty is not allowed! ");
      return;
    }
    sendMessage(textToBeSent);
    this.setState({ textToBeSent: " " });

    window.setTimeout(function() {
      var elem = document.getElementById('data');
      elem.scrollTop = elem.scrollHeight;
    }, 1000);

  }

  render(){
    const { page, itemsInFolder, videos} = this.state
   
      

      return (
        <>
          <NavBarClassRoom itemsInFolder={itemsInFolder} />
    
          <div className="wrapper">
         
              {/* <LandingPageClassRoom /> */}
               
             
              <div className="bg-red-top classroom  no-print">
                <Container>
                
                  <Row>
                    <Col style={{textAlign: 'center'}} lg="12">
                      <h2>Tävling</h2>
                    </Col>
                  </Row>              
                  <Row>
                    <Col>
                      <ReactTypeformEmbed url="https://demo.typeform.com/to/njdbt5" style={{width: '100%', height: '500px'}}/>
                    </Col>
                  </Row> 
                </Container>
              </div>

       
          </div>
        </>
      )

  }

  
}



const mapStateToProps = state => state;
const mapActionToProps = { sendMessage: msgChatMessageRequest };

export default connect(mapStateToProps, mapActionToProps)(Contest);

// export default HomeClassRoom;
