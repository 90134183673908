import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input, InputGroup, InputGroupAddon
} from "reactstrap";
import { RichText } from 'prismic-reactjs'
import Prismic from 'prismic-javascript'
import NavBar from "components/Navbars/NavBar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter"
import Lesson from "components/Lesson"
import FadeIn from 'react-fade-in'
import ReactLoading from 'react-loading'
import { ScrollTo } from "react-scroll-to"
import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LessonSearchByTagV2 from "./LessonSearchByTagV2";

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken })

class Topic extends React.Component {

  constructor(props){

    super(props)

    this.state = {
      doc: null,
      page: null,
      lessonId: null,
      itemsInFolder: 0,
      lessons: [],
      lessonIds: [],
      totalTime: 0,
      value: '',
      copied: false,
      onTopic : false
    }
    
    this.handleItemClick = this.handleItemClick.bind(this)    
    this.addLessonToFolder = this.addLessonToFolder.bind(this)
  }

  async fetchPage() {

    console.log("fetchPage lessonId", this.state.lessonId)

    // let docId = this.props.match.params.uid
    // let searchField = 'document.id'
    // if(docId === "human"){
    //   docId = 'XjClfBAAACIAbqJ4'
    // }

    let that = this
    await Client.query(
        Prismic.Predicates.at('document.id', this.state.lessonId)).then(function(document) {
        console.log("document", document.results[0])
      if (document) {
        that.setState({ page: document.results[0] })
      } else {
        that.setState({ notFound: !document })
      }

    })

  }

  handleItemClick(event, id){
    this.setState({ lessonId: id })
  }

  componentDidMount(){  

    let lessonId = this.props.match.params.uid

    if(lessonId === "plastexperiment"){
      lessonId = 'YgwHzBIAACsAukT9'
    }   

    if(lessonId === "human"){
      lessonId = 'XjClfBAAACIAbqJ4'
    }     
    if(lessonId === "city"){
      lessonId = 'XjCkuBAAACQAbp8L'
      this.setState({ onTopic: true})
    }
    if(lessonId === "sea"){
      lessonId = 'Xh-QrxIAACIAmLGb'
      this.setState({ onTopic: true})
    }
    if(lessonId === 'nature'){
      lessonId = 'XjClFhAAACEAbqC9'
      this.setState({ onTopic: true})
    }


    this.setState({ lessonId: lessonId }, function () {
      console.log(this.state.lessonId);
      this.fetchPage()
    });

    let itemsInFolder = localStorage.getItem("lessons")
    if(itemsInFolder){
      let res = itemsInFolder.split(",");
      this.state.itemsInFolder = res.length
    }

    
  }

  handleCheck(val) {
    return this.state.lessons(item => val.name === item.name);
  }

  addLessonToFolder(){
    
    let lessonId = this.state.lessonId
    let lessons = localStorage.getItem("lessons")
    if(lessons){
      var lessonsArr = JSON.parse(lessons)
      var n = lessonsArr.includes(lessonId);
      if(!n){
        lessonsArr.push(lessonId)
        localStorage.setItem('lessons', JSON.stringify(lessonsArr))
        this.setState({ itemsInFolder: this.state.itemsInFolder + 1 })
        toast.notify("Lektionen finns nu i din mapp");
      }else{
        toast.notify("Lektionen finns redan bland dina valda");
      }
     
    }else{
      this.state.lessonIds.push(lessonId)
      localStorage.setItem('lessons', JSON.stringify(this.state.lessonIds))
      this.setState({ itemsInFolder: this.state.itemsInFolder + 1 })
      toast.notify("Lektionen finns nu i din mapp");
    }
  }

  render(){

    const { page, lessonId, itemsInFolder, lessons, onTopic } = this.state

    let sectionUrl = "/topic/"+ this.props.match.params.uid
    
    console.log("page",  onTopic)

    if(page){

      let menuStyle = "menuTopic_"+ this.props.match.params.uid

      let that = this
      const navigation = page.data.body.map(function(slice, index) {
        return (
          <div key={index} className={menuStyle}>
            {slice.primary.category_title[0].text}
              {slice.items.map(function(item, index){
                return (<Row className="menuLesson"><Col lg="1"><span class="gg-file-document"></span></Col><Col><a href="javascript:void()" onClick={(event) => that.handleItemClick(event, item.lesson.id)}> {onTopic ? item.link_title[0].text : item.lesson.tags[0] }</a></Col></Row>)
              })}
          </div>
        )
      })

      return (
        <>
          
          <NavBar lessonId={lessonId} itemsInFolder={this.state.itemsInFolder} addLessonToFolder={this.addLessonToFolder} time={this.state.totalTime}/>
    
          <div className="wrapper" style={{paddingTop: 80}}>
            <FadeIn>
              <LandingPageHeader uid={this.props.match.params.uid}/>
            </FadeIn>

            <Container className="bg-white first-row">
              <Row>
                <Col>
                <LessonSearchByTagV2 />
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" lg="2">
                  <div className="leftMenu">
                    
                    <Row className={menuStyle}><Col className="back"><span class="gg-chevron-double-left"></span><a href="/teacher" >Lärarrummet</a></Col></Row>

                    <h5 className="lessonTitle">Innehåll</h5>

                    <div className={menuStyle}>
                      
                      <Row className="menuLesson"><Col lg="1"><span class="gg-file-document"></span></Col><Col><a href={sectionUrl}> Introduktion</a></Col></Row>
                    </div>  
                    {navigation}
                    
                  </div>
                </Col>
                <Col name="lessonContent" lg="10">
                  <FadeIn>
                    {!lessonId && RichText.render(page.data.intro)}
                    {lessonId && <Lesson lessonId={lessonId} itemsInFolder={this.state.itemsInFolder} addLessonToFolder={this.addLessonToFolder} uid={this.props.match.params.uid}></Lesson>}
                  </FadeIn>
                </Col>
              </Row>
              
              

            </Container>

            <DarkFooter />

          </div>
        </>
      )

    }
    else{
      return <ReactLoading type={"balls"} color={"#007F9C"} height={'50px'} width={'50px'} />
    }
  }

  
}

export default Topic;
