import React from "react";
import { Container, Row, Col} from "reactstrap";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import NavBarPreSchool from "components/Navbars/NavBarPreSchool";
import LandingPageClassRoomContent from "components/Headers/LandingPageClassRoomContent";
import DarkFooter from "components/Footers/DarkFooter";
import pdk from 'assets/img/pdk.png'
import pdi from 'assets/img/pdi.png'
import pgn from 'assets/img/pgn.png'
import pfin from 'assets/img/pfin.png'
import pno from 'assets/img/pno.png'
import psv from 'assets/img/psv.png'
import pal from 'assets/img/pal.png'
import ic from 'assets/img/ic.png'

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })

class ContentV2 extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      meny: null,
      letter: "all"
    }

  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps)
    this.props = nextProps;
    this.fetchPage()
  }



  handleClick(letterIn) {

    console.log("letterIn", letterIn)
    //this.setState({letter: letterIn})
    let that = this
    Client.query(    
      Prismic.Predicates.any("document.tags", [letterIn]), 
      { orderings : '[my.content.section, my.content.title]',  pageSize : 200 }
    ).then(function(response) {
      console.log("response", response)
      if (response) {
        console.log("response", response)
        that.setState({ meny: response });
      } else {
        that.setState({ meny: !response });
      }
    })

    if(letterIn === "all"){
      this.fetchPage()
    }

  }

  
  componentW1illReceiveProps(nextProps) {
    console.log("nextProps", nextProps)
    this.props = nextProps;
    this.fetchPage()
  }

  componentDidMount(){
    this.fetchPage()
  }
 

  async fetchPage() 
  {

    let that = this

    console.log("this.props.match.params.uid", this.props.match.params.uid)

    const response = await Client.getByUID('content', this.props.match.params.uid).then(function(document) {
      if (document) {
        console.log("document", document)
        that.setState({ page: document });
      } else {
        that.setState({ page: !document });
      }
    })
   
    console.log("response", response)
    console.log("this.props.match.params.menyid", this.props.match.params.menyid)
    
    Client.query(
        Prismic.Predicates.at("document.tags", [this.props.match.params.menyid]), 
        { orderings : '[my.content.section, my.content.header_titel]',  pageSize : 200 }
    ).then(function(response) {
      if (response) {
        that.setState({ meny: response });
      } else {
        that.setState({ meny: !response });
      }
    });



  }

  

  render(){

    const { page, meny } = this.state
    let pageContent = null
    let menyContent = null
    if(page && meny){

      console.log("meny", meny)

      pageContent = page.data
      menyContent = meny.results
      let that = this
      let startPath = ""
      if(that.props.match.params.menyid === "inspiration"){
        startPath = "/contentpage/inspiration/inspiration/"
      }

      if(that.props.match.params.menyid === "Skräprapporten 2020"){
        startPath = "/contentpage/lilla-skraprapporten-2020/Skräprapporten%202020"
      }

      let showIndex = false;
      if(that.props.match.params.menyid === "skrapipedia"){
        showIndex = true;
      }
      
    return (

      <>
        <NavBarPreSchool  />

        <div className="wrapper">

            <LandingPageClassRoomContent uid={this.props.match.params.uid} menyid={this.props.match.params.menyid}/>

            <Container>

                <Row style={{marginBottom: 60}}>
                  <Col lg="3">
                    <h3 className="contentMenu">Innehåll</h3>
                    <Row style={{marginBottom: 60}}>
                      <Col md="12">
                      {showIndex === false && <><i class="fas fa-home leftMenuIcon"></i> <a className="menuLink" href={`${startPath}`}>Start</a></>}
                      </Col>
                      {menyContent.map(function(content) {    
                            return(<>                            
                                <Col md="12" style={{margin:3}}>
                                <i class="fas fa-file-alt leftMenuIcon"></i> <a className="menuLink" href={`/contentpage/${content.uid}/${that.props.match.params.menyid}`} >{content.data.header_titel[0].text}</a>
                                </Col>
                              
                            </>)}
                      )} 
                    </Row>
                  </Col>  

                    
                 
                  <Col md="9" style={{marginTop: 0}}>


                  {
                    showIndex &&
                    <Row>
                      <Col md="12" style={{marginTop: 0}}>
                        <h2 className="title-first">Index A - Ö</h2>
                      </Col>           
                     
                      <Col sm="1" style={{marginTop: 0}}>
                        <button onClick={() => this.handleClick('A')} className="indexButton">A</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('B')} className="indexButton">B</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('C')} className="indexButton">C</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('D')} className="indexButton">D</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('E')} className="indexButton">E</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('F')} className="indexButton">F</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('G')} className="indexButton">G</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('H')} className="indexButton">H</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('I')} className="indexButton">I</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('J')} className="indexButton">J</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('K')} className="indexButton">K</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('L')} className="indexButton">L</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('M')} className="indexButton">M</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('N')} className="indexButton">N</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('O')} className="indexButton">O</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('P')} className="indexButton">P</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('Q')} className="indexButton">Q</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('R')} className="indexButton">R</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('S')} className="indexButton">S</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('T')} className="indexButton">T</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('U')} className="indexButton">U</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('V')} className="indexButton">V</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('W')} className="indexButton">W</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('X')} className="indexButton">X</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('Y')} className="indexButton">Y</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('Z')} className="indexButton">Z</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('Å')} className="indexButton">Å</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('Ä')} className="indexButton">Ä</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                      <button onClick={() => this.handleClick('Ö')} className="indexButton">Ö</button>
                      </Col>
                      <Col sm="1" style={{marginTop: 0}}>
                        <button onClick={() => this.handleClick('all')} className="indexButton">Alla</button>
                      </Col>           
                    </Row>
                  }           

                  {
                  pageContent.body.map(function(content) {
                    
                    if(content.slice_type =="only_text"){
                        return(<>
                          <Row>
                            <Col className="ml-auto mr-auto" md="12">
                              <h2 className="title-first">{content.primary.title[0] && content.primary.title[0].text}</h2>
                              <p className="description">
                                {RichText.render(content.primary.content)}
                                {/* {content.primary.content[0] && content.primary.content[0].text} */}
                              </p>                         
                            </Col>
                          </Row>
                        </>)
                    }


                  if(content.slice_type =="2_columns"){
                    return(<>
                      <Row style={{marginTop: 35}}>
                        {
                          content.items.map(function(item){
                            return(<>
                            <Col className="ml-auto mr-auto" md="6">
                              <h2 className="">{item.column_1[0].text}</h2>
                              <p className="">{RichText.render(item.content) }</p>                            
                            </Col>                          
                            </>)
                          }
                        )}
                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_right"){
                    return(<>
                      <Row>
                        <Col md="6" style={{marginTop: 35}} >
                          <h2 className="">{content.primary.title[0].text}</h2>
                          <p className="">
                            {RichText.render(content.primary.content)}
                          </p>                      
                        </Col>
                        <Col md="6" style={{marginTop: 35}}>
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>                     
                        </Col>  

                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_left"){
                    return(<>
                      <Row>
                        <Col md="6">
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>                         
                        </Col>                          
                        <Col md="6">
                          <h2 className="">{content.primary.title[0].text}</h2>
                          <p className="">{RichText.render(content.primary.content)}</p>                       
                        </Col>    
                      </Row>
                    </>)
                  }
                 
                })
              }
                </Col>
              </Row>
            </Container>
          <DarkFooter />
        </div>
      </>
    )
    }
    else{
      return null
    }
  }
}

export default ContentV2;
