import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

import Prismic from 'prismic-javascript'
import PrismicConfig from '../../prismic-configuration'


const apiEndpoint = 'https://nccweb.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })

class ResultsPageHeader extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      ref: null
    }
    
    this.fetchPage();
    
  }

  async fetchPage() {

    const response = await Client.query(
      Prismic.Predicates.at('document.id', 'XfGDjhEAACIA9FMZ')
    )

    if (response) {
      this.setState({ page: response })
    } else {
      this.setState({ notFound: !response })
    }
  
  }

  render(){

    const { page } = this.state
    let pageContent = null

    if(page){

      pageContent = page.results[0]

      return (
        <>
         
          <div className="page-header page-header-small">
            <div
              className="page-header-image"
              style={{
                backgroundImage: "url(" + pageContent.data.hero_image.url + ")"
              }}
              // ref={pageHeader}
            ></div>
            <div className="content-center">
              <Container>
            <h1 className="title">{this.props.title}</h1>
              </Container>
            </div>
          </div>
        </>
      )
    }
    else{
      return null
    }
  }
}

export default ResultsPageHeader;
