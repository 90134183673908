// const config = {
//     apiKey: "AIzaSyC6hbLOGr0HZPj7BdTkNQmBzt-GpXEwQsM",
//     authDomain: "skrapplabbet.firebaseapp.com",
//     databaseURL: "https://skrapplabbet.firebaseio.com"
//   };
  
//   export default config;


import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyC6hbLOGr0HZPj7BdTkNQmBzt-GpXEwQsM",
  authDomain: "skrapplabbet.firebaseapp.com",
  databaseURL: "https://skrapplabbet.firebaseio.com"
}

const App = firebase.initializeApp(firebaseConfig)

export default App
  