import React from "react";
import { Button, Container, Row, Col, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";
import Prismic from "prismic-javascript";

const apiEndpoint = "https://skraplabbet.cdn.prismic.io/api/v2";
const accessToken = ""; // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken });

export default class LessonSearchByTag extends React.Component {
  constructor(props) {

    super(props)

    this.state = {
      tagFilter: {
        tagKey: null,
        tagName: null,
        subTagKey: null,
      },
      tagData: [],
      subTags: [],
      preselect: false,
      dropdownOpen: false
    }

  }

  async fetchByTag(tagKey, tagName) {
    
    this.setState({
      tagFilter: { tagKey: tagKey, tagName: tagName, subTagKey: null },
    })

    this.setState({
      tagData: [],
      subTags: [],
    })

    console.log("tagName", tagName)

    const response = await Client.query(
    Prismic.Predicates.at("document.tags", [tagName]),
    { pageSize: 100, 
      orderings : '[my.material.order]'  }
    )

    console.log("response", response)

    let subTags = [];
    if (response.results.length > 0) {
      response.results.map((lesson) => {
        lesson.tags.map((tag) => {
          if (tag.startsWith("S_") && subTags.indexOf(tag.substring(2)) < 0) {
            subTags.push(tag.substring(2));
          }
        })
      })

      this.setState({
        tagData: response.results,
        subTags: subTags,
      });
    }
  }

  async fetchBySubTag(subTagKey) {
    this.setState({
      subKey: subTagKey,
      tagData: [],
    });

    const response = await Client.query(
      Prismic.Predicates.at("document.tags", 
      [ this.state.tagFilter.tagName, `S_${subTagKey}`]),
      { 
        pageSize: 40, 
        orderings : '[my.material.order]' 
      }
    );

    if (response.results.length > 0) {
      this.setState({
        tagData: response.results,
      });
    }
  }
  
  componentDidMount(){
    let that = this
    if(this.props.preselect){

      this.setState({preselect:true})
      let strNav = window.location.pathname
      console.log("strNavstrNavstrNav", strNav)

      if(strNav === "/l%C3%A4rarrummet/f%C3%B6rskola"){
        this.fetchByTag("tf0", "Forskola")
      }
      if(strNav === "/l%C3%A4rarrummet/f-3"){
        this.fetchByTag("tf3", "F-3")
      }
      if(strNav === "/l%C3%A4rarrummet/4-6"){
        this.fetchByTag("t46", "4-6")
      }
      if(strNav === "/l%C3%A4rarrummet/7-9"){
        this.fetchByTag("t79", "7-9")
      } 

      
     
      setTimeout(function(){ 
        that.fetchBySubTag("Skrapplockardagarna")
       }, 2000)

     
    }

  }

  renameTag(tagIn) {
    tagIn = tagIn.replace("Forskola", "Förskola")
    tagIn = tagIn.replace("Skrapplockardagarna", "Skräpplockardagarna")
    tagIn = tagIn.replace("Skrapmatning", "Skräpmatning")
    tagIn = tagIn.replace("Fakta om skrap", "Fakta om skräp")
    tagIn = tagIn.replace("Allemansratten", "Allemansrätten")
    tagIn = tagIn.replace("Skrap i havet", "Skräp i havet")
    tagIn = tagIn.replace("Sang och musik", "Sång och musik")
    tagIn = tagIn.replace("Cirkular ekonomi", "Cirkulär ekonomi")
    return tagIn
  }

  render() {

    let that = this

    const { tagFilter, tagData, subTags, preselect } = this.state

    // console.log("preselect", preselect)

    return (
      <Container>
        <Row>
          <Col>
            <h3 >Bläddra genom kategori</h3>
          </Col>               
          <Col className="ml-auto mr-auto" md="12">
              <Row>
                {/* <Col md="3"><a href="/topic/human"><div className="navi human v2">MÄNNISKA</div></a></Col> */}
                <Col md="4"><a href="/topic/city"><div className="navi city">STAD</div></a></Col>
                <Col md="4"><a href="/topic/sea"><div className="navi sea">HAV</div></a></Col>
                <Col md="4"><a href="/topic/nature"><div className="navi nature">NATUR</div></a></Col>
              </Row>
            </Col>
        </Row>

        <Row style={{marginTop: "20px"}}>
          <Col className="ml-auto mr-auto" lg="12">
            <Row>
              <Col>
                <h3 >Bläddra genom årskurs</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="navi cloud v2"                 
                  onClick={() => this.fetchByTag("tf0", "Forskola")}
                  style={{ fontSize: 30, minHeight: 120 }}
                >
                  Förskola
                </Button>
              </Col>
              <Col>
                <Button
                  className="navi city v2"                  
                  onClick={() => this.fetchByTag("tf3", "F-3")}
                  style={{ fontSize: 30, minHeight: 120 }}
                >
                  F-3
                </Button>
              </Col>
              <Col>
                <Button
                  className="navi sea v2"                 
                  onClick={() => this.fetchByTag("t46", "4-6")}
                  style={{ fontSize: 30, minHeight: 120, }}
                >
                  4-6
                </Button>
              </Col>
              <Col>
                <Button
                  className="navi nature v2"
                  size="lg"
                  onClick={() => this.fetchByTag("t79", "7-9")}
                  style={{ 
                    minHeight: 120,
                    fontSize: 30, 
                    // fontSize: 19.3, 
                    padding: 18
                    // // paddingTop: 20,
                    // marginTop: 27,
                    // textAlign: 'center',
                    // verticalAlign: 'middle'
                   }}
                >
                  7-9 / <br></br>Gymnasium
                </Button>
              </Col>
              {/* <Col>
                <Button
                  className="navi"                  
                  onClick={() => this.fetchByTag("tfakta", "fakta")}
                  style={{ 
                    minHeight: 120,
                    fontSize: 30, 
                    // fontSize: 19.3, 
                    padding: 18,
                    backgroundColor: "grey"
                    // // paddingTop: 20,
                    // marginTop: 27,
                    // textAlign: 'center',
                    // verticalAlign: 'middle'
                   }}
                >Fakta
                </Button>
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg="12" style={{ marginTop: 10, marginBottom: 20 }}>
            {subTags.length > 0 && (
              <h4 style={{ marginTop: 0, marginBottom: 0 }}>
                Filtrera på taggar
              </h4>
            )}
            {subTags.map((subTag) => (
              <Button
                style={{
                  borderRadius: "8px",
                  marginRight: 10,
                  fontWeight: 900,
                }}
                onClick={() => this.fetchBySubTag(subTag, subTag)}
              >
                {that.renameTag(subTag)}
              </Button>
            ))}
          </Col>
        </Row>

        {tagFilter.tagKey && (
          <>
            <Row>
              <Col>
                <hr style={{ marginTop: 0, marginBottom: 0 }}></hr>
                <h4>Resultat för: {tagFilter.tagName}</h4>
              </Col>
              {/* <Col style={{textAlign: "right"}}>
              <hr style={{ marginTop: 0, marginBottom: 0 }}></hr>
              <Dropdown fullwidth>
                <DropdownToggle caret>
                  Filtrera ytterligare på ämnen
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>Header</DropdownItem>
                  <DropdownItem>Some Action</DropdownItem>
                  <DropdownItem text>Dropdown Item Text</DropdownItem>
                  <DropdownItem disabled>Action (disabled)</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Foo Action</DropdownItem>
                  <DropdownItem>Bar Action</DropdownItem>
                  <DropdownItem>Quo Action</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </Col> */}
            </Row>

            <Row>
              {tagData.map(function (lesson) {
                
                let trunc =  lesson.data?.intro[0]?.text.substr(0, 70) + "\u2026";

                let link = "";
                link = "/topic/" + lesson.id;

                return (
                  <Col
                    lg="6"
                    style={{
                      borderRadius: 20,
                    }}
                  >
                    <Row
                      style={{
                        borderRadius: 20,
                        padding: "20px",
                        backgroundColor: "#f5f5f5",
                        marginBottom: 30,
                        marginLeft: 0,
                      }}
                    >
                      <Row>
                        <Col lg="3" style={{ minWidth: "120px" }}>
                          <div class="circular">
                            <img
                              src={
                                lesson.data.lesson_image.url +"&rect=0,0,667,667"
                                  ? lesson.data.lesson_image.url +"&rect=0,0,667,667"
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJEK40TRPKbM5JcPw1M6F8ayHInpCGrTNSrg&usqp=CAU"
                              }
                            ></img>
                          </div>
                         
                          

                        </Col>

                        <Col lg="8">
                        <a href={link}
                              style={{ fontSize: 15, color: "#000"}}
                            ><h3>{lesson.data.title[0].text}</h3></a>
                            
                          {/* <span>
                            Uppskattad tid{" "}
                            {lesson.data.aproximateTime
                              ? lesson.data.aproximateTime
                              : "30"}{" "}
                            min
                          </span> */}
                          
                          <p>
                            {trunc}
                          </p>

                          <div style={{marginTop: 20}}>
                            {lesson.tags.map(function (tag) 
                            {
                              let lOut = tag
                              if (lOut.startsWith("S_")) {
                                lOut = tag.substring(2)
                              }
                              return (<span style={{
                                backgroundColor: '#fff',
                                fontSize:10,
                                padding: 5,
                                borderRadius: 5,
                                marginRight: 10
                              }}>{that.renameTag(lOut)}</span>)
                            })}
                          </div>

                        </Col>
                        <Col lg="12">
                          <div
                            style={{
                              marginTop: "20px",
                              marginBottom: 10,
                              float: "right",
                              fontWeight: 900,
                              fontSize: "0.9em",
                            }}
                          >
                            <a
                              href={link}
                              style={{ fontSize: 15, color: "#000" }}
                            >
                              Till lektionen{" "}
                              <i
                                class="fas fa-chevron-circle-right"
                                style={{
                                  color: "#000",
                                  fontSize: "15px",
                                }}
                              ></i>
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </>
        )}

        <Row>
          <Col lg="12"></Col>
        </Row>
      </Container>
    );
  }
}
