import React from "react";
import { Button, Container } from "reactstrap";
import Prismic from 'prismic-javascript'

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })

function huh(){

  console.log("huh")
  let pageHeader = React.createRef();

  if (window.innerWidth > 991) {
    const updateScroll = () => {
      let windowScrollTop = window.pageYOffset / 3;
      pageHeader.current.style.transform =
        "translate3d(0," + windowScrollTop + "px,0)";
    };
    window.addEventListener("scroll", updateScroll);
    return function cleanup() {
      window.removeEventListener("scroll", updateScroll);
    };
  }

  return pageHeader
}

class LandingPageHeaderTeacher extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      ref: null
    }
    
    this.fetchPage();
    this.pageHeader = huh()
    
  }

  async fetchPage() {

    let that = this
    const response = await Client.getByUID('content_page', 'klassrummet').then(function(document) {
      if (document) {
        that.setState({ page: document });
      } else {
        that.setState({ page: !document });
      }
    })

  
  }

  render(){

    const { page } = this.state
    
    let pageContent = null

    if(page){

      pageContent = page
      return (
        <>
          
          
          <div className="page-header page-header-mini">
            <div
              className="page-header-image"
              style={{
                minHeight: "400px",
                backgroundImage: "url(" + page.data.backgrundsbild.url + ")"
              }}
              ref={this.pageHeader}
            ></div>
            <div className="content-center" style={{top: "60% !important"}}>
              <Container>
                <h1 className="title">{page.data.titel[0].text}</h1>
   
              </Container>
            </div>
          </div>
        </>
      )
    }
    else{
      return null
    }
  }

}

export default LandingPageHeaderTeacher;
