import React from "react";
import { Container, Row, Col, Button} from "reactstrap";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import ReactLoading from 'react-loading';
import {CopyToClipboard} from 'react-copy-to-clipboard'

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
 
const Client = Prismic.client(apiEndpoint, { accessToken })

class Lesson extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      lesson: null,
      itemInFolder: 0,
      copied: false,
      itemsInFolder: 0
    }
    
    this.fetchPage()
    
  }

  async fetchPage(lessonIdNew) {
    
    let lessonId
    if(lessonIdNew){
      lessonId = lessonIdNew
    }
    else{
      lessonId = this.props.lessonId
    }
    let that = this
    const response = await Client.getByID(lessonId).then(function(document) {
      if (document) {
          that.setState({ page: document });
          window.scrollTo(0, 0)
      } else {
          that.setState({ notFound: !document });
      }
  })
    
  }

  
  componentDidMount(){
    
    let itemsInFolder = localStorage.getItem("lessons")

    if(itemsInFolder){
      let res = itemsInFolder.split(",");
      // this.state.itemsInFolder = res.length
      this.setState({itemsInFolder: res.length})
    }
    
  }


  componentWillReceiveProps(props) {
    this.setState({lessonId: props.lessonId})
    this.fetchPage(props.lessonId)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  addLessonToFolder(time){
    this.props.addLessonToFolder(time)
  }

  render(){

    const { lessonId, itemsInFolder } = this.props
    const { page, copied }  = this.state
    
    if(page){ 

      console.log("page", page)

      let menuStyle = "roundedTitel"

      let headerImage ={
      }

      let uniqueUrl = "https://skraplabbet.se/topic/"+ this.props.uid +"/"+ lessonId
      let copyLabel = 'Kopiera länk till övningen'
      if(copied){
        copyLabel = "Kopierad!"
      }
      return (
        <>
        
        <div className="lesson-s">
          <Container>
        
              <Row>
                  <Col md="6" className="estimated">
                  {/* <i class="gg-time"></i> Uppskattad tid är {page.data.aproximate_time ? page.data.aproximate_time : 30} minuter */}

                    <div>
                      {page.tags && page.tags.map(function (tag) 
                      {
                        let lOut = tag
                        if (lOut.startsWith("S_")) {
                          lOut = tag.substring(2)
                        }
                        return (<span style={{
                          backgroundColor: '#000',
                          fontSize:13,
                          padding: 5,
                          borderRadius: 5,
                          marginRight: 10
                        }}>{lOut}</span>)
                      })}
                    </div>

                  </Col>
                  <Col md="3">
                    
                    {lessonId !== 'YgwHzBIAACsAukT9' && lessonId !== 'XjClfBAAACIAbqJ4' && lessonId !== 'XjCkuBAAACQAbp8L' &&lessonId !== 'Xh-QrxIAACIAmLGb' && lessonId !== 'XjClFhAAACEAbqC9' &&<Button color="success" className="float-right" onClick={() => this.addLessonToFolder(page.data.aproximate_time)}><i class="fas fa-folder" style={{fontSize: 20}}></i> Spara för lektion</Button>}

                    {/* <Button color="success" className="float-right" onClick={() => this.addLessonToFolder(page.data.aproximate_time)}><i class="gg-printer"></i> Skriv ut</Button> */}
                    
          
                  </Col>
                  <Col md="3">  
                  {lessonId !== 'YgwHzBIAACsAukT9' && <div style={{
                      backgroundColor: "#1c6160", 
                      borderRadius: 9,
                      color: "white",
                      padding:10,
                      marginTop:11,
                      width: 180
                      }}>
                      <i class="fas fa-folder" color="success" style={{
                        fontSize: 20, color: "white", marginLeft:10}}></i> <a href="/lessons" style={{color: "white"}}>Mina lektioner ({itemsInFolder})</a>
                    </div> }
                   </Col>
              </Row>

              <div className={menuStyle} style={headerImage}>
                <Row>
                    <Col md="12" className="lesson-title">
                      <Row>
                        <Col md="12">{RichText.render(page.data.title)} </Col>
                        {/* <Col md="2">
                          <span className="pull-right">
                              {page.data.type_of_material === "Aktivitet" && <i class="gg-pen"></i>}
                              {page.data.type_of_material === "Diskussion" && <i class="gg-comment"></i>}
                              {page.data.type_of_material === "Video" && <i class="gg-film"></i>}
                          </span>
                        </Col> */}
                      </Row>
                    </Col>
                </Row>
              </div>

              
              <Row>
                  <Col md="12">                   
                    {page.data.lesson_image && <img src={page.data.lesson_image?.url} className="introImage" height={'150px'} style={{float: 'left', marginRight:'20px', marginBottom: '30px'}}></img>}
                    {RichText.render(page.data.intro)}
                  </Col>                 
              </Row>
              <Row>
                  <Col md="12">
                    {RichText.render(page.data.content)}
                  </Col>                 
              </Row>              
              {lessonId !== 'YgwHzBIAACsAukT9' && <Row>
                  <Col md="12" className="estimated">

                  {lessonId !== 'YgwHzBIAACsAukT9' && lessonId !== 'XjCkuBAAACQAbp8L' &&lessonId !== 'Xh-QrxIAACIAmLGb' && lessonId !== 'XjClFhAAACEAbqC9' &&<Button color="success" className="float-right" onClick={() => this.addLessonToFolder(page.data.aproximate_time)}><i class="gg-folder-add"></i> Spara för lektion</Button>}

                  {/* <Button color="success" className="float-right"><i class="gg-link"></i> Kopiera länk till övningen</Button> */}

                  <CopyToClipboard text={uniqueUrl}
                      onCopy={() => this.setState({copied: true})}>
                    <Button color="success" href={''}className="pull-right"><i class="gg-copy"></i>{copyLabel}</Button> 
                  </CopyToClipboard>


                  <Button color="success" className="float-right"><i class="gg-printer"></i> Skriv ut</Button>

                  </Col>
                  
              </Row>}
          </Container>
        </div>
        </>
      )

    }
    else{
      return <ReactLoading type={"balls"} color={"#007F9C"} height={'50px'} width={'50px'} />
    }
  }

  
}

export default Lesson;
