import React from "react";
import { Container, Row, Col, Input } from "reactstrap";
import Prismic from "prismic-javascript";

const apiEndpoint = "https://skraplabbet.cdn.prismic.io/api/v2";
const accessToken = ""; // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken });

export default class LessonSearchByText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tagFilter: {
        tagKey: null,
        subTagKey: null,
      },
      tagData: [],
      subTags: [],
    };
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.fetchByTagText(e.target.value);
    }
  };

  handleTagChange = (e) => {
    this.fetchByTag(e.target.value);
  };

  handleSubTagChange = (e) => {
    if(e.target.value){
      this.fetchBySubTag(e.target.value);
    } else {
      this.fetchByTag(this.state.tagFilter.tagKey);
    }
    
  };

  async fetchByTagText(tagKey) {
    this.setState({
      tagFilter: { tagKey: tagKey, subTagKey: null },
    });
    this.setState({
      tagData: [],
      subTags: [],
    });

    const response = await Client.query([
      // Prismic.Predicates.at("document.tags", [tagKey]),
      Prismic.Predicates.at('document.type', 'material'),
      Prismic.Predicates.fulltext('document', tagKey)
    ]);

    if (response.results.length > 0) {
      this.setState({
        tagData: response.results,
      });
    }
  }

  async fetchByTag(tagKey) {
    this.setState({
      tagFilter: { tagKey: tagKey, subTagKey: null },
    });
    this.setState({
      tagData: [],
      subTags: [],
    });

    const response = await Client.query(
      Prismic.Predicates.at("document.tags", [tagKey]),
      { pageSize: 40 }
    );

    let subTags = [];
    if (response.results.length > 0) {
      response.results.map((lesson) => {
        lesson.tags.map((tag) => {
          console.log("tag==> ", tag);
          if (tag.startsWith("S_") && subTags.indexOf(tag.substring(2)) < 0) {
            subTags.push(tag.substring(2));
          }
        });
      });

      console.log("subTags==>", subTags);
      this.setState({
        tagData: response.results,
        subTags: subTags,
      });
    }
  }

  async fetchBySubTag(subTagKey) {
    this.setState({
      subKey: subTagKey,
      tagData: [],
    });

    const response = await Client.query(
      Prismic.Predicates.at("document.tags", [
        this.state.tagFilter.tagKey,
        `S_${subTagKey}`,
      ]),
      { pageSize: 40 }
    );

    if (response.results.length > 0) {
      this.setState({
        tagData: response.results,
      });
    }
  }

  render() {
    const { tagFilter, tagData, subTags } = this.state;

    return (
      <Container className="bg-white first-row">
        <Row>
          <Col className="ml-auto mr-auto" lg="12">
            <Row>
              <Col>
                <h3>Sök lektioner</h3>
                <p>
                    Sök bland våra lektioner!
                    </p>
              </Col>
            </Row>
            <Row>
              <Col lg="6">                
                <Row>
                  <Col>
                    <Input
                      type="text"
                      name="txtTag"
                      id="txtTag"
                      placeholder="Ange sökord..."
                      onKeyDown={this.handleKeyDown}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row>
                  <Col>
                    <Input
                      type="select"
                      name="ddlTag"
                      id="ddlTag"
                      onChange={this.handleTagChange}
                    >
                      <option value=""></option>
                      <option>Förskola</option>
                      <option>F-3</option>
                      <option>4-6</option>
                      <option>7-9</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <p></p>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="select"
                      name="ddlSubTag"
                      id="ddlSubTag"
                      onChange={this.handleSubTagChange}
                    >
                      <option value=""></option>
                      {subTags.map((tag) => {
                        return <option>{tag}</option>;
                      })}
                    </Input>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row>
          <Col lg="12" style={{ marginTop: 10, marginBottom: 20 }}>
            {subTags.length > 0 && (
              <h4 style={{ marginTop: 0, marginBottom: 0 }}>
                Filtrera på taggar
              </h4>
            )}
            {subTags.map((subTag) => (
              <Button
                style={{
                  borderRadius: "8px",
                  marginRight: 10,
                  fontWeight: 900,
                }}
                onClick={() => this.fetchBySubTag(subTag, subTag)}
              >
                {subTag}
              </Button>
            ))}
          </Col>
        </Row> */}

        {tagFilter.tagKey && (
          <>
            <Row>
              <Col>
                <hr style={{ marginTop: 20, marginBottom: 0 }}></hr>
                <h4>Resultat för: {tagFilter.tagKey}</h4>
              </Col>
            </Row>

            <Row>
              {tagData.map(function (lesson) {
                console.log("lesson.data", lesson.data);

                let trunc =  lesson.data?.intro[0]?.text.substr(0, 70) + "\u2026";

                let link = "";
                link = "/topic/" + lesson.id;

                return (
                  <Col
                    lg="6"
                    style={{
                      borderRadius: 20,
                    }}
                  >
                    <Row
                      style={{
                        borderRadius: 20,
                        padding: "20px",
                        backgroundColor: "#f5f5f5",
                        marginBottom: 30,
                        marginRight: 20,
                      }}
                    >
                      <Row>
                        <Col lg="3" style={{ minWidth: "120px" }}>
                          <div class="circular">
                            <img
                              src={
                                lesson.data.lesson_image.url +"&rect=0,0,667,667"
                                  ? lesson.data.lesson_image.url +"&rect=0,0,667,667"
                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJEK40TRPKbM5JcPw1M6F8ayHInpCGrTNSrg&usqp=CAU"
                              }
                            ></img>
                          </div>
                        </Col>

                        <Col lg="8">
                        <a href={link} style={{ fontSize: 15, color: "#000" }}>
                          <h3>{lesson.data.title[0].text}</h3>
                        </a>
                          {/* <span>
                            Uppskattad tid{" "}
                            {lesson.data.aproximateTime
                              ? lesson.data.aproximateTime
                              : "30"}{" "}
                            min
                          </span> */}
                          <p>{trunc}</p>

                          <div style={{marginTop: 20}}>
                            {lesson.tags.map(function (tag) 
                            {
                              let lOut = tag
                              if (lOut.startsWith("S_")) {
                                lOut = tag.substring(2)
                              }
                              return (<span style={{
                                backgroundColor: '#fff',
                                fontSize:10,
                                padding: 5,
                                borderRadius: 5,
                                marginRight: 10
                              }}>{lOut}</span>)
                            })}
                          </div>
                          
                        </Col>
                        <Col lg="12">
                          <div
                            style={{
                              marginTop: "15px",
                              marginBottom: 10,
                              float: "right",
                              fontWeight: 900,
                              fontSize: "0.9em",
                            }}
                          >
                            <a
                              href={link}
                              style={{ fontSize: 15, color: "#000" }}
                            >
                              Till lektionen{" "}
                              <i
                                class="fas fa-chevron-circle-right"
                                style={{
                                  color: "#000",
                                  fontSize: "15",
                                }}
                              ></i>
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </>
        )}

        <Row>
          <Col lg="12"></Col>
        </Row>
      </Container>
    );
  }
}
