import React from "react";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import { Row, Col } from "reactstrap";
import circle from "assets/img/right-arrow-circle.png";
import smallwhite from "assets/img/small-white.png";

const apiEndpoint = "https://skraplabbet.cdn.prismic.io/api/v2";
const accessToken = ""; // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken });

export default class SkrapiBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: null,
      content: null,
      link: null,
    };

    this.fetchPage();
  }

  async fetchPage() {
    const response = await Client.query(
      Prismic.Predicates.any("document.tags", ["skrapipedia-frontpage"]),
      { pageSize: 200 }
    ).then(response => {

      const length = response.results.length;
      const index = length === 1 ? 0 : Math.floor(Math.random() * (length - 1)) + 1;
      const doc = response.results[index];

      this.setState({
        title: doc?.data.header_titel[0].text,
        content: doc?.data.body[0].primary.content,
        link: (doc.slugs && doc.slugs.length>0) ? `https://skraplabbet.se/contentpage/${doc.slugs[0]}/skrapipedia/B` : null,
        image: doc.data.hero_image.url,
      })

    })

  }

  render() {
    const { title, link, content, image } = this.state;
    return (
      <div className="homeBoxRandom" style={{ marginTop: 30, maxWidth: 540 }}>
        <Row>
          <Col>
            <h3 style={{ marginTop: 0, marginBottom: 0, color: "#000" }}
            >
              Skräpipedia
            </h3>
            <p style={{ color: "#000" }}>
            Här får du ett smakprov från Skräpipedia, där vi samlar vår kunskap om skräpet. Kunskapen kommer från forskare, experter och egna undersökningar.
            </p>
            <hr style={{ color: "#000" }}></hr>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="homeTitle4" style={{ color: "#000" }}>
              {title && title}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col style={{ color: "#000" }}>
            {RichText.render(content)}
            {image && <img src={image} style={{
                      float: "right",   
                      width: '100%',
                      borderRadius: "20px"
                    }}></img>}
            {link  && (
              <Row>
                <Col>
                  <div
                    style={{
                      float: "right",
                      fontWeight: 900,
                      fontSize: "0.9em",
                    }}
                  >
                    <a
                      href="/skräpipedia"
                      style={{ fontSize: 15, color: "#000" }}                     
                    >
                      Läs mer{" "}
                      <i
                        class="fas fa-chevron-circle-right"
                        style={{ color: "#000", fontSize: "15px" }}
                      ></i>
                    </a>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
