import React from "react";
import { Button, Container } from "reactstrap";
import Prismic from 'prismic-javascript'

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })

function huh(){

  let pageHeader = React.createRef();

  // if (window.innerWidth > 991) {
  //   const updateScroll = () => {
  //     let windowScrollTop = window.pageYOffset / 3;
  //     pageHeader.current.style.transform =
  //       "translate3d(0," + windowScrollTop + "px,0)";
  //   };
  //   window.addEventListener("scroll", updateScroll);
  //   return function cleanup() {
  //     window.removeEventListener("scroll", updateScroll);
  //   };
  // }

  return pageHeader
}

class LandingPageHeaderTeacher extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      ref: null,
      menyid: null,
      pageHeader: null
    }
    
    this.fetchPage()
    if(this.props.menyid === 'skrapipedia'){
      this.getHeader()
    }
    else{

    }
    

    this.pageHeader = huh()
    
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps)
    this.props = nextProps;
    this.fetchPage()
  }

  async fetchPage() {

    let that = this
    let paramIn
    // if(this.props.match.path === "/skräpipedia"){
    //   paramIn = "skrapipedia"
    // }

    if(this.props.uid){
      paramIn = this.props.uid
    }else{
      paramIn = "skrapipedia"
    }

    
    const response = await Client.getByUID('content', paramIn).then(function(document) {
      if (document) {
        that.setState({ page: document });
      } else {
        that.setState({ page: !document });
      }
    })

  
  }

  async getHeader() {

    let that = this
    const response = await Client.getByUID('content', 'skrapipedia').then(function(document) {
      if (document) {
        that.setState({ pageHeader: document });
      } else {
        that.setState({ pageHeader: !document });
      }
    })

  
  }

  render(){

    const { page, pageHeader } = this.state
    
    let pageContent = null
    let pageHeaderContent = null

    if(page){

      pageContent = page
      pageHeaderContent = pageHeader

      let title = ''
      let imageContent = ''
      
      if(this.props.menyid === "skrapipedia"){
        title = "Skräpipedia"
        imageContent = pageHeaderContent 
      }
      else{
        title = page.data.header_titel[0].text
        imageContent = pageContent
      }

      return (
        <>
          
          
          <div className="page-header page-header-mini">
            <div
              className="page-header-image"
              style={{
                minHeight: "400px",
                backgroundImage: "url(https://images.prismic.io/skraplabbet/afec1123-7e62-4255-b009-75639287ad55_Skrapplockning_2016_HSR+-+stor+%28277976%29.jpg?auto=compress,format)"
              }}
              ref={this.pageHeader}
            ></div>
            <div className="content-center" style={{top: "60% !important"}}>
              <Container>
                <h1 className="titleClassRoom">{title}</h1>
   
              </Container>
            </div>
          </div>
        </>
      )
    }
    else{
      return null
    }
  }

}

export default LandingPageHeaderTeacher;
