import React from "react";
import { Container, Row, Col, Button, Media} from "reactstrap";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import discussion from 'assets/img/ikon_till_diskussion.svg'
import ReactLoading from 'react-loading';
import posed from 'react-pose';
import Animate from 'react-smooth';
import ReactStopwatch from 'react-stopwatch';
import arrowDown from 'assets/img/arrowdown.png'
import ImagePicker from 'react-image-picker'
import 'react-image-picker/dist/index.css'
//import images from local
import img1 from 'assets/img/88.png'
import img2 from 'assets/img/88.png'
import img3 from 'assets/img/88.png'
import img4 from 'assets/img/88.png'
import cup from 'assets/img/cup.png'
import scrollToComponent from 'react-scroll-to-component';
import { Thumbs } from "react-responsive-carousel";
 
const ms = require("pretty-ms")

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
 
const Client = Prismic.client(apiEndpoint, { accessToken })

const imageList = []

const steps = [{
    style: {
      height: 0,
      opacity: 0,
    },
    duration: 1000,
    }, 
    {
    style: {
      opacity: 1,
     
    },
    duration: 1000,
  },
    {
    style: {
      height: 0,
      opacity: 0
    },
    duration: 1000,
  }

];

const stepsShow = [{
  style: {
    opacity: 0,
  },
  duration: 1,
  }, 
  {
  style: {
    opacity: 1,
  },
  duration: 1000,
  }

];

let ob = []; 
const questionsLevel_1_Category_1 = []
const questionsLevel_1_Category_2 = []
const questionsLevel_1_Category_3 = []

const questionsLevel_2_Category_1 = []
const questionsLevel_2_Category_2 = []
const questionsLevel_2_Category_3 = []

const questionsLevel_3_Category_1 = []
const questionsLevel_3_Category_2 = []
const questionsLevel_3_Category_3 = []

class SkrapipediaMaster extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      lesson: null,
      itemInFolder: 0,
      copied: false,
      page : '',
      selectedExpert: 0,
      isOpen1: false,
      level1Visible: "block",
      level2Visible: "none",
      level3Visible: "none",
      finish: "none",
      renderLevel: true,
      clicked: false,
      time: 0,
      isOn: false,
      start: 0
    }

    this.levelCleared = false
    this.randomLevel1 = null
    this.randomLevel2 = null
    this.randomLevel3 = null

    this.onPick = this.onPick.bind(this)
    this.level2 = this.level2.bind(this)
    this.level3 = this.level3.bind(this)
    this.finish = this.finish.bind(this)

    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
  }

  startTimer() {
    this.setState({
      isOn: true,
      time: this.state.time,
      start: Date.now() - this.state.time
    })
    this.timer = setInterval(() => this.setState({
      time: Date.now() - this.state.start
    }), 1);
  }
  stopTimer() {
    this.setState({isOn: false})
    clearInterval(this.timer)
  }
  resetTimer() {
    this.setState({time: 0, isOn: false})
  }

  level2(){

    this.setState({levelCleared: false})
    this.setState({clicked: false})
    this.setState({level1Visible: "none"})
    this.setState({level2Visible: "block"})
    scrollToComponent(this.level2ref, { offset: -150, align: 'top', duration: 500})
  }

  level3(){
    this.setState({levelCleared: false})
    this.setState({clicked: false})
    this.setState({level2Visible: "none"})
    this.setState({level3Visible: "block"})
    scrollToComponent(this.level3ref, { offset: -300, align: 'top', duration: 500})
  }

  finish(){
    this.stopTimer() 
    this.setState({level3Visible: "none"})
    this.setState({finish: "block"})
    scrollToComponent(this.levelFinishref, { offset: -300, align: 'top', duration: 500})
  }
  
  componentDidMount(){
    this.startTimer() 
  }
  

  componentWillReceiveProps(props) {
    this.setState({selectedExpert: props.selectedExpert})
    this.setState({isOpen1: true})
  }

  componentWillMount(){

   

    Client.query([
      Prismic.Predicates.at('document.type', 'content'),
      Prismic.Predicates.at('document.tags', ['skrapipedia'])
    ],  
    { pageSize : 200 }).then(res => {
      

    
      //Loop and get level one questions
      for (const [index, result] of res.results.entries()) {

        //Loop question from results
        for (const [index, question] of result.data.questions.entries()) {

          
          //LEVEL 1
          if(question.belongs_to_level === 1 && question.category === 1 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_1_Category_1.push(question)
          }

          if(question.belongs_to_level === 1 && question.category === 2 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_1_Category_2.push(question)
          }

          if(question.belongs_to_level === 1 && question.category === 3 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_1_Category_3.push(question)
          }

          //LEVEL 2
          if(question.belongs_to_level === 2 && question.category === 1 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_2_Category_1.push(question)
          }

          if(question.belongs_to_level === 2 && question.category === 2 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_2_Category_2.push(question)
          }

          if(question.belongs_to_level === 2 && question.category === 3 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_2_Category_3.push(question)
          }

          //LEVEL 3
          if(question.belongs_to_level === 3 && question.category === 1 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_3_Category_1.push(question)
          }

          if(question.belongs_to_level === 3 && question.category === 2 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_3_Category_2.push(question)
          }

          if(question.belongs_to_level === 3 && question.category === 3 && question.belongs_to_group === "Klassrummet"){
            questionsLevel_3_Category_3.push(question)
          }

        }

      }
      
      ob["questionsLevel_1_Category_1"] = questionsLevel_1_Category_1
      ob["questionsLevel_1_Category_2"] = questionsLevel_1_Category_2
      ob["questionsLevel_1_Category_3"] = questionsLevel_1_Category_3

      ob["questionsLevel_2_Category_1"] = questionsLevel_2_Category_1
      ob["questionsLevel_2_Category_2"] = questionsLevel_2_Category_2
      ob["questionsLevel_2_Category_3"] = questionsLevel_2_Category_3

      ob["questionsLevel_3_Category_1"] = questionsLevel_3_Category_1
      ob["questionsLevel_3_Category_2"] = questionsLevel_3_Category_2
      ob["questionsLevel_3_Category_3"] = questionsLevel_3_Category_3


      console.log(ob)
    })

  }

  getRandomQuestionForLevel(level, category){


    const arr = ob["questionsLevel_"+ level +"_Category_"+ category] 

    let rand = Math.floor(Math.random() * 3)
    if(rand === -1){
      rand = 0
    }

    console.log("arr", arr)

    let randomQuestion

    if(this.randomLevel1 === null){
      this.randomLevel1 = rand
      randomQuestion = arr[rand]
    }
    else{
      randomQuestion = arr[this.randomLevel1]
    }

    if(this.randomLevel2 === null){
      this.randomLevel2 = rand
      randomQuestion = arr[rand]
    }
    else{
      randomQuestion = arr[this.randomLevel2]
    }

    if(this.randomLevel3 === null){
      this.randomLevel3 = rand
      randomQuestion = arr[rand]
    }
    else{
      randomQuestion = arr[this.randomLevel3]
    }

    console.log("rand", rand)
    console.log("category", category)
    console.log("randomQuestion", randomQuestion)

    const imageList = [
    randomQuestion.image11.url, 
    randomQuestion.image21.url, 
    randomQuestion.image31.url]
  
    const correct = randomQuestion.correct_image1

    return (
      <>
        <p className="questionHeader">{randomQuestion.question_title}</p>
        {/* <p className="questionDesc">{randomQuestion.question_description}</p> */}
        <Row>
          <Col lg={12}>
            <ImagePicker disabled
            style={{width:'100%'}}
            images={imageList.map((image, i) => ({src: image, value: i}))}
            // onPick={() => this.onPick(randomQuestion.correct_image1)}
            onPick={(image) => this.onPick(image, correct)}
            />
          </Col>
        </Row>
        
      </>
    )

    
    
  }

  onPick(image, correctImage) {
    
    this.clicked = true

    let levelCleared = false
    if(image.value === (correctImage-1)){
      levelCleared = true
    }

    this.setState({levelCleared: levelCleared})
    this.setState({clicked: true})


  }

  printMe(){

    window.print()
  }

  render(){

    

    const { selectedExpert } = this.props
    const { level1Visible, level2Visible, level3Visible, finish } = this.state
    
    let gameName = ''
    switch(selectedExpert) {
      case 1:
        gameName = "Vanligaste Skräpet"
        break;
      case 2:
        gameName = "Nedbrytningstider"
        break;
      case 3:
        gameName = "Hur ska det sorteras?"
        break;
      case 4:
        gameName = "NATUR"   
        break;
    }

      if(gameName){

        return (
          <>
          
          <div className="skrapipedia-master">
            <Container>
              <Row>
                <Col style={{textAlign: 'center'}}>
                  <h2>VALT EXPERTISOMRÅDE : <span className="gameName">{gameName}</span></h2>
                </Col>
              </Row>
              <Row>
                <Col style={{textAlign: 'center'}}>
                  <h3 className="readySet">  
  
                    <Animate steps={steps} begin={1000}>
                      <p className="one">Klara...</p>
                    </Animate>   

                    <Animate steps={steps} begin={2000}>
                      <p className="two">Färdiga...</p>
                    </Animate>   

                    <Animate steps={steps} begin={3000}>
                      <p className="three">Kör...</p>
                    </Animate>  
                  </h3> 
                </Col>
              </Row>

              <div style={{display: level1Visible}} ref={(section) => { this.level1ref = section; }}>
                <Animate steps={stepsShow} begin={5000}>
                  <Row style={{marginTop: "-150px"}}>                  
                      <Col style={{textAlign: 'center'}}>
                        <h4 className="level">  
                          LEVEL 1
                        </h4> 
                        <h5>Din tid: {ms(this.state.time)}</h5>
                      </Col>
                  </Row>
                  <Col lg="12" style={{textAlign: 'center', marginTop: '30px'}}>
                      <p className="answerCorrect">Svara rätt på följande fråga:</p>
                    </Col>
                  <Row>
                    
                    <Col lg="12">
                      {this.getRandomQuestionForLevel(1, selectedExpert)}
                    </Col>
                  
                          
                    {this.state.levelCleared  &&
                      <Col lg="12" style={{textAlign: 'center', marginTop: '60px', marginBottom: '60px'}}>
                        <img src={arrowDown} onClick={this.level2} style={{cursor: 'pointer'}}></img>
                        <br></br>
                        <span className="correctMe"><a onClick={this.level2} style={{cursor: 'pointer'}}>NÄSTA NIVÅ!</a></span>
                      </Col>
                    }

                    {!this.state.levelCleared && this.state.clicked &&
                      <Col lg="12" style={{textAlign: 'center', marginTop: '60px', marginBottom: '60px'}}>
                        <img src={arrowDown} style={{cursor: 'pointer'}}></img>
                        <br></br>
                        <span className="correctMeWrong"><a  style={{cursor: 'pointer'}}>Oh nej, det var fel. Försök igen!</a></span>
                      </Col>
                    }



                  </Row>
                </Animate>
              </div>

              <div style={{display: level2Visible}} ref={(section) => { this.level2ref = section; }}>
                <Row style={{marginTop: "-10px"}}>                  
                    <Col style={{textAlign: 'center'}}>
                      <h4 className="level">  
                        LEVEL 2
                      </h4> 
                      <h5>Din tid: {ms(this.state.time)}</h5>
                    </Col>
                </Row>
                <Col lg="12" style={{textAlign: 'center', marginTop: '30px'}}>
                    <p className="answerCorrect">Svara rätt på följande fråga:</p>
                  </Col>
                <Row>
                  <Col lg="12">
                      {this.getRandomQuestionForLevel(2, selectedExpert)}
                  </Col>
                  <Col lg="12" style={{textAlign: 'center', marginTop: '30px'}}>
                    
                    {this.state.levelCleared && 
                      <Col lg="12" style={{textAlign: 'center', marginTop: '60px', marginBottom: '60px'}}>
                        <img src={arrowDown} onClick={this.level3} style={{cursor: 'pointer'}}></img>
                        <br></br>
                        <span className="correctMe"><a onClick={this.level3} style={{cursor: 'pointer'}}>NÄSTA NIVÅ!</a></span>
                      </Col>
                    }

                    {!this.state.levelCleared && this.state.clicked &&
                      <Col lg="12" style={{textAlign: 'center', marginTop: '60px', marginBottom: '60px'}}>
                        <img src={arrowDown}  style={{cursor: 'pointer'}}></img>
                        <br></br>
                        <span className="correctMeWrong"><a  style={{cursor: 'pointer'}}>Oh nej, det var fel. Försök igen!</a></span>
                      </Col>
                    }

                    {/* <img src={arrowDown} onClick={this.level3}></img>
                    <br></br>
                    <span className="correctMe"><a onClick={this.level3}>NÄSTA NIVÅ!</a></span> */}

                  </Col>
                </Row>
              </div>
              
              <div style={{display: level3Visible}} ref={(section) => { this.level3ref = section; }}>
                <Row style={{marginTop: "-10px"}}>                  
                    <Col style={{textAlign: 'center'}}>
                      <h4 className="level">  
                        LEVEL 3
                      </h4> 
                      <h5>Din tid: {ms(this.state.time)}</h5>
                    </Col>
                </Row>
                <Col lg="12" style={{textAlign: 'center', marginTop: '30px'}}>
                    <p>Svara rätt på följande fråga:</p>
                  </Col>
                <Row>
                  <Col lg="12">
                      {this.getRandomQuestionForLevel(3, selectedExpert)}
                  </Col>
                  <Col lg="12" style={{textAlign: 'center', marginTop: '30px'}}>

                  {this.state.levelCleared && 
                      <Col lg="12" style={{textAlign: 'center', marginTop: '60px', marginBottom: '60px'}}>
                        <img src={arrowDown} onClick={this.finish} style={{cursor: 'pointer'}}></img>
                        <br></br>
                        <span className="correctMe"><a onClick={this.finish} style={{cursor: 'pointer'}}>KLAR!</a></span>
                      </Col>
                    }

                    {!this.state.levelCleared && this.state.clicked &&
                      <Col lg="12" style={{textAlign: 'center', marginTop: '60px', marginBottom: '60px'}}>
                        <img src={arrowDown}  style={{cursor: 'pointer'}}></img>
                        <br></br>
                        <span className="correctMeWrong"><a  style={{cursor: 'pointer'}}>Oh nej, det var fel. Försök igen!</a></span>
                      </Col>
                    }

                    {/* <img src={arrowDown} onClick={this.finish}></img>
                    <br></br>
                    <span className="correctMe"><a onClick={this.finish}>KLAR!</a></span> */}
                  </Col>
                </Row>
              </div>

              <div style={{display: finish}} ref={(section) => { this.levelFinishref = section; }} id="printme">
                <Row style={{marginTop: "-10px"}}>                  
                    <Col style={{textAlign: 'center'}}>
                      <span className="levelFinish">  
                        Du är Miljöexpert!
                      </span> 
                      <span className="levelFinish"><div>På endast  {ms(this.state.time)}!</div></span>
                    </Col>
                </Row>
                <Col lg="12" style={{textAlign: 'center', marginTop: '30px'}}>
                    <img src={cup} height="300" className='printTest'></img>
                    <br></br><br></br>
                    <p className="printMe no-print" onClick={this.printMe}><i class="fas fa-print"></i> Skriv ut din diplom!</p>
                    <br></br><br></br>
                    <p className="startOver no-print "><a href="/klassrummet"><span className="startOver" style={{color: 'white'}}>Börja om utmana en vän!</span></a></p>
                </Col>               
              </div>

              

            </Container>
          </div>
          </>
        )


      }
      else{
        return null
      }
     

    
  }

  
}

export default SkrapipediaMaster;
