import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import "assets/css/bootstrap.min.css"
import "assets/scss/now-ui-kit.scss"
import "assets/demo/demo.css"
import "assets/demo/nucleo-icons-page-styles.css"
import "assets/css/skraplabbet.css"
import Home from "views/Home"
import SkrapSkoj from "views/SkrapSkoj"
import HomeTeacher from "views/HomeTeacher"
import Content from "views/Content"
import Topic from "views/Topic"
import TopicPlast from "views/TopicPlast"
import Lessons from "views/Lessons"
import Me from "views/Me"
import HomeClassRoom from "views/HomeClassRoom"
import store from "./store"
import storeFun from "./storeFun"
import ContentPage from "views/ContentV2"
import ContentPagePreSchool from "views/ContentV2PreSchool"
import HomePreSchool from "views/HomePreSchool"
import CookieConsent from "react-cookie-consent"
import Story from "views/Story"
import Contest from "views/Contest"
import ContestV2 from "views/ContestV2"
import ContentV3 from "views/ContentV3"
const createElement = (Component, props) => {
  return <Component key={`${props.route.name}RouteComponent`} {...props}/>
}

ReactDOM.render(
  
  <Provider store={store} storeFun={storeFun}>
    <CookieConsent>
    This website uses cookies to enhance the user experience.
    </CookieConsent>
    <BrowserRouter>    
      <Switch>

        <Redirect from="/filmer" to="/contentpage/filmer/f" />
        {/* <Route
          path="/filmer" exact
          render={props => <ContentPage {...props} key={props => 'contentpage/filmer/f'}/>}
        /> */}

      
        <Route
          path="/tavling" exact
          render={props => <ContestV2 {...props} key={props => props.route.path}/>}
        />
        <Route
          path="/tävling" exact
          render={props => <ContestV2 {...props} key={props => props.route.path}/>}
        />
        <Route
          path="/klassrummet" exact
          render={props => <HomeClassRoom {...props} />}
        />
        <Route
          path="/forskola" exact
          render={props => <HomePreSchool {...props} />}
        />
        <Route
          path="/me" exact
          render={props => <Me {...props} />}
        />
        <Route
          path="/me/:uid" exact
          render={props => <Me {...props} />}
        />
        <Route
          path="/" exact
          render={props => <Home {...props} />}
        />
        <Route
          path="/lessons" exact
          render={props => <Lessons {...props} />}
        />
        <Route
          path="/teacher" exact
          render={props => <HomeTeacher {...props} />}
        />
        <Route
          path="/lärarrummet/:sel" exact
          render={props => <HomeTeacher {...props} preselect={true}/>}
        />
        <Route
          path="/topic/:uid" exact
          render={props => <Topic {...props} />}
        />
        {/* <Route
          path="/topic/:uid/:lessonId" exact
          render={props => <Topic {...props} />}
        /> */}
        <Route
            path="/content/:uid" exact
            render={props => <Content {...props} />}
        />
         {/* <Route
            path="/plastexpetimentet" exact
            render={props => <Content {...props} />}
        /> */}
        <Route
            path="/story/:uid" exact
            render={props => <Story {...props} />}
        />

        <Route key={props => props.route.path}
            path="/contentpage/:uid/:menyid" exact
            render={props => <ContentPage {...props} key={props => props.route.path}/>}
        />

        <Route key={props => props.route.path}
            path="/contentpagepreschool/:uid/:menyid" exact
            render={props => <ContentPagePreSchool {...props} key={props => props.route.path}/>}
        />


        <Route key={props => props.route.path}
            path="/contentpage/:uid/:menyid/:subid" exact
            render={props => <ContentPage {...props} key={props => props.route.path}/>}
        />
        

        <Route key={props => props.route.path}
            path="/innehall/:uid" exact
            render={props => <ContentV3 {...props} key={props => props.route.path}/>}
        />

        <Route key={props => props.route.path}
            path="/skräpipedia" exact
            render={props => <ContentPage {...props} key={props => props.route.path}/>}
        />

        <Route
          path="/skrapskoj" exact
          render={props => <SkrapSkoj {...props} />}
        />

        <Route key={props => props.route.path}
            path="/innehall/crypticurlwinner" exact
            render={props => <ContentV3 {...props} key={props => props.route.path}/>}
        />

        <Route key={props => props.route.path}
            path="/sektion/:uid/:sub" exact
            render={props => <TopicPlast {...props} key={props => props.route.path}/>}
        />

        <Route key={props => props.route.path}
            path="/sektion/:uid" exact
            render={props => <TopicPlast {...props} key={props => props.route.path}/>}
        />

        {/* sektion/YiOhjxIAAC4As72P/plastexperiment */}
        
        {/* <Route key={props => props.route.path}
            path="/contentpage/:uid/:menyid/:letter" exact
            render={props => <ContentPage {...props} key={props => props.route.path}/>}
        /> */}

      </Switch>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById("root")
)
