import React from "react";
import { Container, Row, Col} from "reactstrap";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import NavBar from "components/Navbars/NavBar.js";
import AboutPageHeader from "components/Headers/AboutPageHeader";
import DarkFooter from "components/Footers/DarkFooter";
import pdk from 'assets/img/pdk.png'
import pdi from 'assets/img/pdi.png'
import pgn from 'assets/img/pgn.png'
import pfin from 'assets/img/pfin.png'
import pno from 'assets/img/pno.png'
import psv from 'assets/img/psv.png'
import pal from 'assets/img/pal.png'
import ic from 'assets/img/ic.png'
import NavBarPreSchool from "components/Navbars/NavBarPreSchool"
import Slider from "react-slick"

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })

class Content extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null
    }
    
    this.fetchPage();
    
  }

  async fetchPage() {

    //const doc = await client.getByUID('page', 'about-us')
    // doc contains the document content

    console.log("this.props.match.params.uid", this.props.match.params.uid)
    let that = this
    const response = await Client.getByID(this.props.match.params.uid).then(function(document) {
      // var category = document.data.belongs_to_category;
      // // the variable author now works like a top-level document
      // var categoryTitle = category.data.title;
      console.log("document", document);

      if (document) {
        that.setState({ page: document });
      } else {
        that.setState({ notFound: !response });
      }
    });
    
    // Client.getByUID(
    //   Prismic.Predicates.at('document.id', this.props.match.params.uid)
    // )

    console.log("response", response)

    
  
  }

  render(){

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            initialSlide: 0
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        }
      ]
    };
    const { page } = this.state
    let pageContent = null

    if(page){

      pageContent = page.data
     
      let title = pageContent.title ? pageContent.title[0].text : ""
      let image = pageContent.image ? pageContent.image.url : ""
    return (

      <>
        <NavBarPreSchool  />

            <div className="wrapper">

            <div className="page-header page-header-small" >
              <div
                className="page-header-image"
                style={{
                  backgroundImage: "url(" + image + ")"
                }} 
              ></div>
              <div className="content-center" style={{height:100}}>
                <Container >
                  <h2>{title}</h2>
                </Container>
              </div>
            </div>

            <Container>
            {pageContent &&
                pageContent.body.map(function(content) {      

                  if(content.type === "paragraph"){ 
                    return(<Row><Col className="ml-auto mr-auto" md="6">
                      <p>{ content.text }</p>
                    </Col>
                  </Row>)
                  }

                  if(content.type === "image"){ 
                    return(<Row><Col className="ml-auto mr-auto" md="6">
                      <p><img src={content.url} class="rounded"></img></p>
                    </Col>
                  </Row>)
                  }
                  
                })
              }
              
              {
                pageContent.body.map(function(content) {
                  
                  if(content.slice_type =="only_text"){
                      return(<>
                        <Row>
                          <Col className="ml-auto mr-auto" md="12">
                            <h2 className="title-first">{content.primary.title[0] && content.primary.title[0].text}</h2>
                            <h5 className="description">
                              {content.primary.content[0] && content.primary.content[0].text}
                            </h5>
                            <br></br>
                            <br></br>
                            <br></br>
                          </Col>
                        </Row>
                      </>)
                  }


                  if(content.slice_type =="2_columns"){
                    return(<>
                      <Row>

                        {
                          content.items.map(function(item){
                            return(<>
                            <Col className="ml-auto mr-auto" md="6">
                              <h2 className="title">{item.column_1[0].text}</h2>
                              <h5 className="description">{item.content[0].text}</h5>
                              <br></br>
                              <br></br>
                              <br></br>
                            </Col>
                          
                            </>)
                          }
                        )}

                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_right"){
                    return(<>
                      <Row>
                        <Col className="ml-auto mr-auto" md="6">
                          <h2 className="title">{content.primary.title[0].text}</h2>
                          <h5 className="description">
                            {RichText.render(content.primary.content)}
                          </h5>
                          <br></br>
                          <br></br>
                          <br></br>
                        </Col>
                        <Col className="ml-auto mr-auto" md="6">
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>
                          <br></br>
                              <br></br>
                              <br></br>
                        </Col>  

                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_left"){
                    return(<>
                      <Row>

                        <Col className="ml-auto mr-auto" md="6">
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>
                          <br></br>
                              <br></br>
                              <br></br>
                        </Col>  
                        
                        <Col className="ml-auto mr-auto" md="6">
                          <h2 className="title">{content.primary.title[0].text}</h2>
                          <h5 className="description">{content.primary.content[0].text}</h5>
                          <br></br>
                          <br></br>
                          <br></br>
                        </Col>
                        

                      </Row>
                    </>)
                  }
                 
                })
              }

              <h2>{pageContent.body1[0].primary.name_of_the_gallery[0].text}</h2>
              
              <Slider {...settings} style={{marginTop: '40px', marginBottom: '80px'}}>
                { 
                pageContent.body1 && pageContent.body1[0].items.map(function(content){                    
                    return(
                      <>
                        <Row>
                          <Col className="ml-auto mr-auto" md="6">
                            <p>{ content.image_captions[0].text }</p>
                          </Col>
                          <Col className="ml-auto mr-auto" md="6">
                            <p><img src={content.gallery_image.url} class="rounded"></img></p>
                          </Col>
                        </Row>

                      </>
                    )
                  }
                )
              }
            </Slider>

              


            </Container>

    
          <DarkFooter />
        </div>
      </>
    )
    }
    else{
      return null
    }
  }
}

export default Content;
