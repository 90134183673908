import React from "react";
import { Container, Row, Col} from "reactstrap";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import NavBarClassRoom from "components/Navbars/NavBarClassRoom";
import LandingPageContest from "components/Headers/LandingPageContest";
import DarkFooter from "components/Footers/DarkFooter";
import pdk from 'assets/img/pdk.png'
import pdi from 'assets/img/pdi.png'
import pgn from 'assets/img/pgn.png'
import pno from 'assets/img/pno.png'
import psv from 'assets/img/psv.png'
import pal from 'assets/img/pal.png'
import ic from 'assets/img/ic.png'
import { Widget } from '@typeform/embed-react'

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })

class ContentV2 extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      meny: null,
      letter: "A"
    }

  }

  componentWillReceiveProps(nextProps) {

    console.log("nextProps", nextProps)
    this.props = nextProps;
    this.fetchPage()
    if(this.props.match.params.menyid === "skrapipedia"){
      this.handleClick("A")
    }
  }



  handleClick(letterIn) {

    let that = this
    console.log("letterIn", letterIn)

    if(letterIn !== "all"){
      Client.query(    
        Prismic.Predicates.any("document.tags", [letterIn]), 
        { orderings : '[my.content.extra_index, my.content.section, my.content.title]',  pageSize : 200 }
      ).then(function(response) {
        if (response) {
          that.setState({ meny: response });
        } else {
          that.setState({ meny: !response });
        }
      })
    }
   



    if(letterIn === "all"){
      this.fetchPage()
      this.renderMenu()
    }

    this.setState({ clickedOnLetter: letterIn });

  }

  componentDidMount(){
    this.fetchPage()
    if(this.props.match.params.menyid === "skrapipedia"){
      this.handleClick("A")
    }

    if(this.props.match.params.subid !== undefined){
      this.handleClick(this.props.match.params.subid)
    }
  }
 

  async fetchPage() 
  {

    let that = this
    const response = await Client.getByUID('content', 'tavling').then(function(document) {
      if (document) {
        console.log("document", document)
        that.setState({ page: document });
      } else {
        that.setState({ page: !document });
      }
    })

    if(this.props.match.params.menyid !== "skrapipedia"){
      //this.renderMenu()
    }

  }

  renderMenu(){

    let that = this    
    Client.query(
        Prismic.Predicates.at("document.tags", [this.props.match.params.menyid]), 
        { orderings : '[my.content.extra_index, my.content.title desc]',  pageSize : 200 }
    ).then(function(response) {
      if (response) {
        that.setState({ meny: response });
      } else {
        that.setState({ meny: !response });
      }
    })

  }
  render(){

    const { page, meny, clickedOnLetter } = this.state
    let pageContent = null
    let menyContent = null
    if(page){

      console.log("meny", meny)

      pageContent = page.data
      
      let that = this
      let startPath = ""
      let showIndex = false;
         
    return (

      <>
        <NavBarClassRoom  />

        <div className="wrapper">

            <LandingPageContest uid={'tavling'}/>

            <Container>

                <Row style={{marginBottom: 60}}>
                  
                    
                 
                  <Col md="12" style={{marginTop: 0}}>

                 
                 
                  {
                  pageContent.body.map(function(content) {
                    
                    if(content.slice_type =="only_text"){
                        return(<>
                          <Row>
                            <Col className="ml-auto mr-auto" md="12">
                              <h2 className="title-first">{content.primary.title[0] && content.primary.title[0].text}</h2>
                              <p className="description">
                                {RichText.render(content.primary.content)}
                                {/* {content.primary.content[0] && content.primary.content[0].text} */}
                              </p>                         
                            </Col>
                          </Row>
                        </>)
                    }


                  if(content.slice_type =="2_columns"){
                    return(<>
                      <Row style={{marginTop: 35}}>
                        {
                          content.items.map(function(item){
                            return(<>
                            <Col className="ml-auto mr-auto" md="6">
                              <h2 className="">{item.column_1[0].text}</h2>
                              <p className="">{RichText.render(item.content) }</p>                            
                            </Col>                          
                            </>)
                          }
                        )}
                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_right"){

                    let iframe = content.primary.content[0].text.replace("[]", '<iframe width="100%" height="300" src="https://form.apsis.one/MJC8HHc1qXx7mp" frameborder="0"></iframe>')

                    return(<>
                      <Row>
                        <Col md="6" style={{marginTop: 35}} >
                          <h2 className="">{content.primary.title[0].text}</h2>
                          <p className="">
                            {/* {RichText.render(content.primary.content[0].text)} */}
                            {/* <div dangerouslySetInnerHTML={{ __html: '<div class="typeform-widget" data-url="https://form.typeform.com/to/QU09jk6p?typeform-medium=embed-snippet" style="width: 100%; height: 500px;"></div> <script> (function() {​​​​​​​​ var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) {​​​​​​​​ js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) }​​​​​​​​ }​​​​​​​​)() </script>' }} /> */}
                            {/* {iframe} */}
                            <Widget id="QU09jk6p" style={{ width: '100%', height: 500 }} />
                          </p>                      
                        </Col>
                        <Col md="6" style={{marginTop: 35}}>
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>                     
                        </Col>  

                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_left"){
                    return(<>
                      <Row>
                        <Col md="6">
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>                         
                        </Col>                          
                        <Col md="6">
                          <h2 className="">{content.primary.title[0].text}</h2>
                          <p className="">{RichText.render(content.primary.content)}</p>                       
                        </Col>    
                      </Row>
                    </>)
                  }
                 
                })
              }

                  

                </Col>
              </Row>
              {/* <Row>
                        <Col md="12">
                        <iframe width="100%" height="300" src="https://form.apsis.one/MJC8HHc1qXx7mp" frameborder="0"></iframe>

                        </Col>
                        </Row> */}
            </Container>
          <DarkFooter />
        </div>
      </>
    )
    }
    else{
      return null
    }
  }
}

export default ContentV2;
