import React from "react";
import { Container, Row, Col} from "reactstrap";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'

import LandingPageHeaderV3 from "components/Headers/LandingPageHeaderV3";
import DarkFooter from "components/Footers/DarkFooter";

import NavBar from "components/Navbars/NavBar.js";
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { Widget } from '@typeform/embed-react'

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })
// const { width, height } = useWindowSize()

class ContentV3 extends React.Component {

  constructor(props){
    
    super(props);

    this.state = {
      doc: null,
      page: null,
      meny: null,
      letter: "A"
    }
  }

  componentWillReceiveProps(nextProps) {

    console.log("nextProps", nextProps)
    this.props = nextProps;
    this.fetchPage()
    if(this.props.match.params.menyid === "skrapipedia"){
      this.handleClick("A")
    }
  }

  handleClick(letterIn) {

    let that = this
    console.log("letterIn", letterIn)

    if(letterIn !== "all"){
      Client.query(    
        Prismic.Predicates.any("document.tags", [letterIn]), 
        { orderings : '[my.content.extra_index, my.content.section, my.content.title]',  pageSize : 200 }
      ).then(function(response) {
        if (response) {
          that.setState({ meny: response });
        } else {
          that.setState({ meny: !response });
        }
      })
    }
   



    if(letterIn === "all"){
      this.fetchPage()
      this.renderMenu()
    }

    this.setState({ clickedOnLetter: letterIn });

  }

  componentDidMount(){
    this.fetchPage()
    if(this.props.match.params.menyid === "skrapipedia"){
      this.handleClick("A")
    }

    if(this.props.match.params.subid !== undefined){
      this.handleClick(this.props.match.params.subid)
    }
  }
 

  async fetchPage() 
  {

    let that = this
    console.log("fetchPage", this.props.match.params.uid)
    const response = await Client.getByUID('content', this.props.match.params.uid).then(function(document) {
      console.log("document", document)
      if (document) {
        console.log("document", document)
        that.setState({ page: document });
      } else {
        that.setState({ page: !document });
      }
    })

    if(this.props.match.params.menyid !== "skrapipedia"){
      //this.renderMenu()
    }

  }

  renderMenu(){

    let that = this    
    Client.query(
        Prismic.Predicates.at("document.tags", [this.props.match.params.menyid]), 
        { orderings : '[my.content.extra_index, my.content.title desc]',  pageSize : 200 }
    ).then(function(response) {
      if (response) {
        that.setState({ meny: response });
      } else {
        that.setState({ meny: !response });
      }
    })

  }
  render(){

    const { page, meny, clickedOnLetter } = this.state
    let pageContent = null
    let menyContent = null
    if(page ){

      console.log("meny", meny)

      pageContent = page.data
      // menyContent = meny.results

      let that = this
      let startPath = ""
      if(that.props.match.params.menyid === "inspiration"){
        startPath = "/contentpage/inspiration/inspiration/"
      }

      if(that.props.match.params.menyid === "Skräprapporten 2020"){
        startPath = "/contentpage/lilla-skraprapporten-2020/Skräprapporten%202020"
      }

      let showIndex = false;
      if(that.props.match.params.menyid === "skrapipedia"){
        showIndex = true;
      }
      
      console.log("render", this.props.match.params.uid)
      
    return (

      <>

        {this.props.match.params.uid === "crypticurlwinner" && 
          <Confetti/>
        }

        <NavBar uid={'skrapskoj'}/>

        <div className="wrapper">

            <LandingPageHeaderV3 uid={this.props.match.params.uid}/>

            <Container>

                <Row style={{marginBottom: 60}}>
                  {/* <Col lg="3">
                    <h3 className="contentMenu">Innehåll</h3>
                    <Row style={{marginBottom: 60}}>
                      <Col md="12">
                      {showIndex === false && <><i class="fas fa-home leftMenuIcon"></i> <a className="menuLink" href={`${startPath}`}>Start</a></>}
                      </Col>
                      {menyContent.map(function(content) {    
                            return(<>                            
                                <Col md="12" style={{margin:3}}>
                                <i class="fas fa-file-alt leftMenuIcon"></i>&nbsp;<a className="menuLink" href={`/contentpage/${content.uid}/${that.props.match.params.menyid}/${clickedOnLetter}`}>
                                  {content.data.header_titel[0] && content.data.header_titel[0].text}
                                </a>
                                </Col>
                              
                            </>)}
                      )} 
                    </Row>
                  </Col>   */}

                    
                 
                  <Col md="12" style={{marginTop: 0}}>                

                  {
             
                  pageContent?.body.map(function(content, index) {
                    console.log("index", index)
                    if(content.slice_type == "only_text"){                      
                        return(
                        <>
                          <Row>
                            <Col className="ml-auto mr-auto" md="12">
                              <h2 className="title-first">{content.primary?.title[0] && content.primary?.title[0]?.text}</h2>
                              <p className="description">
                                
                                {content && content?.primary?.content[0]?.text?.toString().includes("games") && 
                                  <>
                                  <div className="homeBoxChat" style={{marginTop: 0, backgroundColor: '#000'}}>
                                    <iframe src="https://admin.skraplabbet.se/Games/" title="Games" style={{width:'100%', height: 700, border: 0}}>
                                    </iframe></div>
                                  </>
                                }

                                {!content?.primary?.content[0]?.text?.toString().includes("games") && 
                                  <>
                                     {RichText.render(content.primary.content)}
                                  </>
                                }


                                {/* {content.primary.content[0] && content.primary.content[0].text} */}
                              </p>             
                            
                            </Col>
                          </Row>
                          
                        </>)
                       
                    }

                  

                  if(content.slice_type =="2_columns"){
                    return(<>
                      <Row style={{marginTop: 35}}>
                        {
                          content.items.map(function(item){
                            return(<>
                            
                            <Col className="ml-auto mr-auto" md="6">
                              <h2 className="">{item.column_1[0].text}</h2>
                              <p className="">{RichText.render(item.content) }</p>                            
                            </Col>                          
                            </>)
                          }
                        )}
                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_right"){
                    return(<>
                      <Row>
                        <Col md="6" style={{marginTop: 60}} >
                          <h2 className="">{content.primary.title[0].text}</h2>
                          <p className="">
                            {RichText.render(content.primary.content)}
                          </p>                      
                        </Col>
                        <Col md="6" style={{marginTop: 60}}>
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>                     
                        </Col>  

                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_left"){
                    return(<>
                      <Row>
                        <Col md="6" style={{marginTop: 60}} >
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>                         
                        </Col>                          
                        <Col md="6" style={{marginTop: 60}} >
                          <h2 className="">{content.primary.title[0].text}</h2>
                          <p className="">{RichText.render(content.primary.content)}</p>                       
                        </Col>    
                      </Row>
                    </>)
                  }
                 
                })
              }
                </Col>
              </Row>
            </Container>
          <DarkFooter />
        </div>
      </>
    )
    }
    else{
      return null
    }
  }
}

export default ContentV3;
