/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row , Col} from "reactstrap";

import footlogo1 from 'assets/img/footlogo1.png'
import footlogo2 from 'assets/img/footlogo2.png'
import logoTop from 'assets/img/logoneg.png'

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <Row>
          <Col>
            <nav>
              <ul>
                <li>
                
                Skräplabbet är skapat av Håll Sverige Rent för att ge barn och elever kunskap och verktyg för att skapa en bättre och renare värld.
                    <br></br>
                   
                    <br></br>
                    Kontakt: gronflagg@hsr.se<br></br>
Håll Sverige Rent: hållsverigerent.se
                        </li>
               
                
              </ul>
            </nav>
          </Col>
          <Col>
            <div className="copyright" id="copyright" style={{textAlign: "center"}}>             
              <br></br>
              <img src={logoTop} height="60"></img>
            </div>
          </Col>
        </Row>
        
        
      </Container>
    </footer>
  );
}

export default DarkFooter;
