import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input, CardColumns, InputGroupAddon, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Form, FormGroup, Label 
} from "reactstrap";
import { RichText } from 'prismic-reactjs'
import Prismic from 'prismic-javascript'
import NavBar from "components/Navbars/NavBar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter"
import Lesson from "components/Lesson"
import FadeIn from 'react-fade-in'
import ReactLoading from 'react-loading'
import { ScrollTo } from "react-scroll-to"
import toast from 'toasted-notes' 
import 'toasted-notes/src/styles.css';
import firebase from "./FirebaseConfig";
import uuid from 'react-uuid'
import slugify from 'react-slugify'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import LessonSearchByTag from "./LessonSearchByTag"

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
 
const Client = Prismic.client(apiEndpoint, { accessToken })

class Lessons extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      lessonId: null,
      itemsInFolder: 0,
      lessonsList:  localStorage.getItem("lessons"),
      lessonsContent: null,
      packagesName: [],
      packagesIds: [],
      prismicId: [],
      packageTitle: null,
      packageId: null,
      showDeletePackage: true,
      isOwner: false
    }
    
    this.fetchPage()
    
    this.handleItemClick = this.handleItemClick.bind(this)    

  }

  async fetchPage() {

    let that = this;
    const response = await Client.getByUID('content_page', 'lessons').then(function(document) {
      if (document) {
        that.setState({ page: document });
      } else {
        that.setState({ notFound: !document });
      }
    })

  }

  handleItemClick(event, id){
    this.setState({ lessonId: id })
  }

  componentDidMount(){  

    if(this.props.match.params.uid){

      let packageId = this.props.match.params.uid

      this.setState(() => ({ packageId: packageId }))
      let that = this
      firebase.database().ref("/lessons/"+ packageId).once('value').then(function(res) {
        //console.log("res.val()", res.val())
        let packageIds = JSON.stringify(res.val().lessonsList)
        if(packageIds){

          that.setState({ packageTitle: res.val().packageName})
          that.setState({ showDeletePackage: false})
          that.getLessonsById(packageIds)
          that.ensureOwner(packageId)
          that.loadPackagesName()

        }else{
          that.getLessonsById(localStorage.getItem(that.props.match.params.uid))
        }

      })
     
      
    }
    else{
      this.setState({ isOwner: true})
    }

    this.loadPackagesName()

  }

  ensureOwner(packageId){
    let packages = localStorage.getItem("packages")
    if(packages){
      let packageList = JSON.parse(packages)
      var exists = packageList.indexOf(packageId)    
      console.log("exists", exists)
      if(exists > -1){
        this.setState({ isOwner: true})
      }
    }
  }

  loadPackagesName(){

    if(localStorage.getItem("packagesName")){

      let packages = localStorage.getItem("packagesName")
      let packagesName = JSON.parse(packages)
      this.setState({ packagesName: packagesName })

      let packageList = localStorage.getItem("packages")
      let packageIds = JSON.parse(packageList)
      this.setState({ packagesIds: packageIds })

    }

  }

  handleItemClick(event, id, name){

   
    this.setState({ packageId: id })
    this.setState({ prismicId: localStorage.getItem(id)})
    this.setState({ packageTitle: name})

    this.getLessonsById(localStorage.getItem(id))
    this.ensureOwner(id)

  }

  deletePackage(event, id){
    
    let ref = firebase.database().ref("/lessons/"+ id);

    let packages = localStorage.getItem("packages")
    let packageList = JSON.parse(packages)

    let packagesNames = localStorage.getItem("packagesName")
    let packagesName = JSON.parse(packagesNames)

    var indexToRemove = packageList.indexOf(id);

    var myname = packagesName[indexToRemove]
    var myid = packageList[indexToRemove]

    packagesName.splice(indexToRemove, 1)
    packageList.splice(indexToRemove, 1)

    localStorage.setItem('packages', JSON.stringify(packageList))
    localStorage.setItem('packagesName', JSON.stringify(packagesName))

    this.setState({ prismicId: []})
    this.setState({ packageId: null})
    this.setState({ packageTitle: null})
    this.setState({ lessonsContent: null })

    ref.remove()
    localStorage.removeItem(id)

    this.loadPackagesName()

  }

  async getLessonsById(ids){

    let that = this
      Client.getByIDs(JSON.parse(ids)).then(function(response) {
        //console.log("response", response)
        that.setState({ lessonsContent: response })
      })
   
  }

  render(){

    const { page, lessonId, itemsInFolder, packagesName, packagesIds,  packageTitle, lessonsContent, packageId, isOwner } = this.state
    
    console.log("this.state", this.state)

    if(page && packagesName){

      let that = this
      const navigation = packagesName.map(function(item, index) {
        //console.log("item", item)
        return (
          <Row className="menuLesson"><Col lg="1"><i class="gg-folder"></i></Col><Col><a href="javascript:void()" onClick={(event) => that.handleItemClick(event, packagesIds[index], item)}> {item}</a></Col></Row>
        )
      })

      let directLink = "https://skraplabbet.se/me/"+ packageId

      return (
        <>

          <NavBar lessonId={lessonId} itemsInFolder={itemsInFolder} addLessonToFolder={this.addLessonToFolder}/>
    
          <div className="wrapper">

              <FadeIn>
                <LandingPageHeader uid={'me'}/>
              </FadeIn>

              <Container className="bg-white first-row">
                
                <Row>

                  {isOwner && <Col lg="2">
                    <h5 className="lessonTitle">Lektionsplaneringar</h5>
                    <div className="menuTopic_sea">
                      {navigation}
                    </div>
                  </Col>}
                 
                  <Col md="10">

                    <Row style={{marginBottom:"-30px"}}>
                      <Col>
                          <h2>{packageTitle}</h2>
                      </Col>
                      <Col>{lessonsContent && isOwner && <Button onClick={(event) => that.deletePackage(event, packageId)} color="secondary"  className="pull-right"><i class="gg-trash"></i> Radera denna lektionsplanering</Button>}</Col>
                    </Row>

                    <Row>
                      <Col>
                        {packageId && <p><small>Direkt länk till denna planering: <a href={directLink} className="contentLink">{directLink}</a></small></p>}
                      </Col>
                    </Row>
                   
                    <CardColumns>
                      {lessonsContent 
                        && lessonsContent.results.map(function(item, index)
                        {


                        let naviCat
                        if(item.data.category==="hav"){
                          naviCat = "sea"
                        }

                        if(item.data.category==="natur"){
                          naviCat = "nature"
                        }

                        if(item.data.category==="manniska"){
                          naviCat = "human"
                        }

                        if(item.data.category==="stad"){
                          naviCat = "city"
                        }

                        return (
                          
                            <Card>
                              <img class="card-img-top" src={item.data.lesson_image.url} alt=""></img>
                              <CardBody>
                                <CardTitle><h3>{item.data.title[0] && item.data.title[0].text}</h3></CardTitle>
                                <CardText>{item.data.intro[0] && item.data.intro[0].text}</CardText>
                                <Button color="primary" href={'/topic/'+ item.id} className="pull-right"><i class="gg-readme"></i> Läs mer</Button>
                              </CardBody>
                            </Card>
                        
                          )
                        })
                      }

                      
                    </CardColumns>
                    {lessonsContent === null &&
                        <Col>
                        <p>Välj bland dina lektionsplaneringar för att bläddra i deras innehåll.</p>
                        </Col>
                      }

                  </Col>
                </Row>

              </Container>

              <Container className="bg-white first-row">
                
              <LessonSearchByTag preselect={this.state.preselect}/>

              </Container>

              <DarkFooter />
          </div>
        </>
      )

    }
    else{
      return <ReactLoading type={"balls"} color={"#007F9C"} height={'50px'} width={'50px'} />
    }
  }

  
}

export default Lessons;
