import React from "react";
import { Button, Container, Jumbotron } from "reactstrap";
import Prismic from 'prismic-javascript'
import logo from 'assets/img/logolive.png'
const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })

function huh(){

  console.log("huh")
  let pageHeader = React.createRef();

  if (window.innerWidth > 991) {
    const updateScroll = () => {
      let windowScrollTop = window.pageYOffset / 3;
      pageHeader.current.style.transform =
        "translate3d(0," + windowScrollTop + "px,0)";
    };
    window.addEventListener("scroll", updateScroll);
    return function cleanup() {
      window.removeEventListener("scroll", updateScroll);
    };
  }

  return pageHeader
}

class LandingPageHeaderV3 extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      ref: null
    }
    
    this.fetchPage();
   
    
  }

  async fetchPage() {

    const response = await Client.getByUID('content', this.props.uid)
    if (response) {
      this.setState({ page: response })
    } else {
      this.setState({ notFound: !response })
    }
  
  }

  render(){

    const { page } = this.state
    let style, h1style

    style="page-header page-header-mini"

    h1style = { 
      top: "60% !important", 
      left: "40% !important"
    }

    console.log("this.props.uid",  page && page.data.hero_image.url)

    if(page){
      return (
        <>
        
          <div className={style}>
            <div
              className="page-header-image"
              style={{
                minHeight: "100px",
                backgroundImage: "url(" + page.data.hero_image.url + ")"
              }}
             
            ></div>
            <div className="content-center ey">
              <Container>           
                <h1 className="title">
                  {page && page.data.header_titel[0].text}
                  </h1>
              </Container>
            </div>
          </div>
        </>
      )
    }
    else{
      return null
    }
  }

}

export default LandingPageHeaderV3;
