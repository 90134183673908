import React from "react";
import { Container, Row, Col} from "reactstrap";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import NavBar from "components/Navbars/NavBar.js";
import AboutPageHeader from "components/Headers/AboutPageHeader";
import DarkFooter from "components/Footers/DarkFooter";
import pdk from 'assets/img/pdk.png'
import pdi from 'assets/img/pdi.png'
import pgn from 'assets/img/pgn.png'
import pfin from 'assets/img/pfin.png'
import pno from 'assets/img/pno.png'
import psv from 'assets/img/psv.png'
import pal from 'assets/img/pal.png'
import ic from 'assets/img/ic.png'

const apiEndpoint = 'https://nccweb.cdn.prismic.io/api/v2'
const accessToken = '' 
const Client = Prismic.client(apiEndpoint, { accessToken })

class Content extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null
    }
    
    this.fetchPage();
    
  }

  async fetchPage() {

    //const doc = await client.getByUID('page', 'about-us')
    // doc contains the document content

    const response = await Client.getByUID('contentpage', this.props.match.params.uid, {'fetchLinks': 'subtopic.title'}).then(function(document) {
      var category = document.data.belongs_to_category;
      // the variable author now works like a top-level document
      var categoryTitle = category.data.title;
      console.log("categoryTitle", categoryTitle);
    });
    
    // Client.getByUID(
    //   Prismic.Predicates.at('document.id', this.props.match.params.uid)
    // )

    console.log("response", response)

    if (response) {
      this.setState({ page: response });
    } else {
      this.setState({ notFound: !response });
    }
  
  }

  render(){

    const { page } = this.state
    let pageContent = null

    if(page){

      pageContent = page.data

    return (

      <>
        <NavBar />

        <div className="wrapper">

            <AboutPageHeader title={pageContent.header_titel[0].text}/>

            <Container>
              
              {
                pageContent.body.map(function(content) {
                  
                  if(content.slice_type =="only_text"){
                      return(<>
                        <Row>
                          <Col className="ml-auto mr-auto" md="12">
                            <h2 className="title-first">{content.primary.title[0] && content.primary.title[0].text}</h2>
                            <h5 className="description">
                              {content.primary.content[0] && content.primary.content[0].text}
                            </h5>
                            <br></br>
                            <br></br>
                            <br></br>
                          </Col>
                        </Row>
                      </>)
                  }


                  if(content.slice_type =="2_columns"){
                    return(<>
                      <Row>

                        {
                          content.items.map(function(item){
                            return(<>
                            <Col className="ml-auto mr-auto" md="6">
                              <h2 className="title">{item.column_1[0].text}</h2>
                              <h5 className="description">{item.content[0].text}</h5>
                              <br></br>
                              <br></br>
                              <br></br>
                            </Col>
                          
                            </>)
                          }
                        )}

                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_right"){
                    return(<>
                      <Row>
                        <Col className="ml-auto mr-auto" md="6">
                          <h2 className="title">{content.primary.title[0].text}</h2>
                          <h5 className="description">
                            {RichText.render(content.primary.content)}
                          </h5>
                          <br></br>
                          <br></br>
                          <br></br>
                        </Col>
                        <Col className="ml-auto mr-auto" md="6">
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>
                          <br></br>
                              <br></br>
                              <br></br>
                        </Col>  

                      </Row>
                    </>)
                  }


                  if(content.slice_type =="image_to_the_left"){
                    return(<>
                      <Row>

                        <Col className="ml-auto mr-auto" md="6">
                          <img src={content.primary.image.url} alt="" class="rounded img-raised"/>
                          <br></br>
                              <br></br>
                              <br></br>
                        </Col>  
                        
                        <Col className="ml-auto mr-auto" md="6">
                          <h2 className="title">{content.primary.title[0].text}</h2>
                          <h5 className="description">{content.primary.content[0].text}</h5>
                          <br></br>
                          <br></br>
                          <br></br>
                        </Col>
                        

                      </Row>
                    </>)
                  }
                 
                })
              }


            </Container>

            <div class="join">
              <Container>
                  <Row>
                    <Col className="ml-auto mr-auto" md="12">
                      <h2 className="title-first">Look at our results by country</h2>
                    </Col>
                  </Row>
                  <Row>
                        <Col className="ml-auto mr-auto" md="3" style={{textAlign:"center"}}>
                          <a href="/country/denmark"><img src={pdk} alt="DK"/></a>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3" style={{textAlign:"center"}}>
                        <a href="/country/faroeisland"><img src={pdi} alt="FI"/></a>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3" style={{textAlign:"center"}}>
                        <a href="/country/greenland"><img src={pgn} alt="GL"/></a>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3" style={{textAlign:"center"}}>
                        <a href="/country/finland"><img src={pfin} alt="FI"/></a>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col className="ml-auto mr-auto" md="3" style={{textAlign:"center"}}>
                        <a href="/country/norway"><img src={pno} alt="NO"/></a>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3" style={{textAlign:"center"}}>
                        <a href="/country/sweden"><img src={psv} alt=""/></a>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3" style={{textAlign:"center"}}>
                        <a href="/country/aland"><img src={pal} alt=""/></a>
                        </Col>
                        <Col className="ml-auto mr-auto" md="3" style={{textAlign:"center"}}>
                        <a href="/country/iceland"><img src={ic} alt=""/></a>
                        </Col>
                    </Row>
                    <br></br>
                    <br></br>
                </Container>
              </div>
    
          <DarkFooter />
        </div>
      </>
    )
    }
    else{
      return null
    }
  }
}

export default Content;
