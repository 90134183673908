import React from "react";
import { Link } from "react-router-dom";
import { Collapse, NavbarBrand,Navbar,NavItem, NavLink, Nav,Container} from "reactstrap";
import logo from 'assets/img/logolive.png'
import logoTop from 'assets/img/hsrlogo.png'

function NavBarClassRoom(props) {

  const [navbarColor, setNavbarColor] = React.useState("")
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [lessonId, setLessonId] = React.useState("")
  const [lessonsInFolder, setLessonsInFolder] = React.useState(0)

  React.useEffect(() => {
    
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("");
      }
    }

    const addLessonToFolder = () => {
      lessonsInFolder = lessonsInFolder + 1
      setLessonsInFolder(setLessonsInFolder)
    }

    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    }
    
  })

  let showPackagesLink = false
  let packages = localStorage.getItem("packages")
  if(packages){
    showPackagesLink = true
  }

  return (
    <>
      {collapseOpen ? 
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
       : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container>
          
          <div className="navbar-translate">
            <NavbarBrand
              href="/"
              id="navbar-brand"
            >
              <img src={logo} height="60"></img>
            </NavbarBrand>
          
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open")
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button">
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
            
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar> 
            <Nav navbar>
              <NavItem>
                <NavLink to="/klassrummet" tag={Link}>
                  <span style={{color: 'black', fontSize: "15px"}}>
                    <i class="fas fa-home"></i><br></br>
                    Start
                  </span>
                </NavLink>
              </NavItem> 
              <NavItem>
                <NavLink to="/contentpage/spel/spel" tag={Link}>
                  <span style={{color: 'black', fontSize: "15px"}}>
                    <i class="fas fa-gamepad"></i><br></br>
                    Spel
                    </span>
                </NavLink>
              </NavItem> 
              <NavItem>
                <NavLink to="/contentpage/filmer/filmer" tag={Link}>
                  <span style={{color: 'black', fontSize: "15px"}}>
                  <i class="fas fa-film"></i><br></br>
                    Filmer</span>
                </NavLink>
              </NavItem> 
              {/* <NavItem>
                <NavLink to="/classroom" tag={Link}>
                  <span style={{color: 'black', fontSize: "15px"}}>Fråga Britta</span>
                </NavLink>
              </NavItem>  */}
              <NavItem>
                <NavLink to="/contentpage/skrapipedia/skrapipedia" tag={Link}>
                  <span style={{color: 'black', fontSize: "15px"}}>
                  <i class="fas fa-book-open"></i><br></br>
                    Fakta</span>
                </NavLink>
              </NavItem> 
              <NavItem>
                <a href="http://www.hsr.se" target="_blank"><img src={logoTop} height="70" className="logoTop"></img></a>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default NavBarClassRoom;
