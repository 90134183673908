import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Collapse, NavbarBrand,Navbar,NavItem, NavLink, Nav,Container, NavbarToggler} from "reactstrap";
import logo from 'assets/img/logolive.png'
import logoTop from 'assets/img/hsrlogo.png'

function NavBar(props) {

  const [navbarColor, setNavbarColor] = React.useState("")
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [lessonId, setLessonId] = React.useState("")
  const [lessonsInFolder, setLessonsInFolder] = React.useState(0)
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  React.useEffect(() => {
    
    
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("");
      }
    }

    const addLessonToFolder = () => {
      lessonsInFolder = lessonsInFolder + 1
      setLessonsInFolder(setLessonsInFolder)
    }

    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    }
    
  })

  let showPackagesLink = false
  let packages = localStorage.getItem("packages")
  if(packages){
    showPackagesLink = true
  }

  //console.log("window.location.pathname", window.location.pathname)

  let strNav = window.location.pathname
  console.log("strNav", strNav)

  let active = {
    backgroundColor: "#fff", 
    borderRadius: 10, 
    opacity: 1,
    cursor: 'hand'
  }

  console.log("strNav", strNav)

  return (
    <>
      {collapseOpen ? 
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
       : null}


      <div className="mobile-menu">
      <Navbar color="faded" light>
        <NavbarBrand href="/" className="mr-auto"><img src={logo} height="30"></img></NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-1" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/">Hem</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/teacher">Lärarrummet</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/klassrummet">Klassrummet</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/skrapskoj">Skräpskoj</NavLink>
            </NavItem>
    
            <NavItem>
              <NavLink href="/innehall/om-skraplabbet">Om</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>


      <Navbar className={"desktop-logo fixed-top " + navbarColor} color="info" expand="lg">
        <Container>
          
          <div className="navbar-translate desktop-logo">
            <NavbarBrand
              href="/"
              id="navbar-brand"
            >
              <img src={logo} height="60"></img>
            </NavbarBrand>

    

            {/* <nav class="navbar navbar-dark bg-dark">
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation"  onClick={() => {
                document.documentElement.classList.toggle("nav-open")
                setCollapseOpen(!collapseOpen);
              }}>
                <span class="navbar-toggler-icon"></span>
              </button>
            </nav> */}

            {/* <button
              className="navbar-toggler navbar-dark bg-dark"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open")
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button">
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button> */}
            
          </div>

          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar> 
            <Nav navbar>
              <NavItem>

                {strNav === "/" ? (
                  <NavLink to="/" tag={Link} style={active}>                  
                    <i class="fas fa-home" style={{color: '#808080', fontSize: "30px", marginLeft: -1, marginRight: 0, }}></i>
                    <br></br>
                    <span style={{color: '#464B4B', fontSize: "14px"}}>Hem</span>
                  </NavLink>
                  ):(
                    <NavLink to="/" tag={Link}>                  
                    <i class="fas fa-home" style={{color: '#808080', fontSize: "30px", marginLeft: -1, marginRight: 0}}></i>
                    <br></br>
                    <span style={{color: '#464B4B', fontSize: "14px"}}>Hem</span>
                  </NavLink>
                  )
                }
              </NavItem> 
              <NavItem>
                
                {
                
                strNav === "/teacher" || strNav.includes("/l%C3%A4rarrummet/skr%C3%A4plockardagarna")  ? (
                  <NavLink to="/teacher" tag={Link} style={active}>                  
                    <i class="fas fa-door-open" style={{color: '#1C6160', fontSize: "30px", marginLeft: 40}}></i>
                    <br></br>
                    <span style={{color: '#464B4B', fontSize: "14px"}}> Lärarrummet</span>
                  </NavLink>
                  ):(
                    <NavLink to="/teacher" tag={Link}>                  
                      <i class="fas fa-door-open" style={{color: '#1C6160', fontSize: "30px", marginLeft: 40}}></i>
                      <br></br>
                      <span style={{color: '#464B4B', fontSize: "14px"}}> Lärarrummet</span>
                    </NavLink>
                  )
                }

              </NavItem> 
              <NavItem>

                {strNav === "/klassrummet" || strNav.includes("topic") ? (
                  <NavLink to="/klassrummet" tag={Link} style={active}>
                  <i class="fas fa-book" style={{color: '#FF9A00', fontSize: "30px", marginLeft: 40}}></i>
                    <br></br>
                    <span style={{color: '#464B4B', fontSize: "14px"}}> Klassrummet</span>
                  </NavLink>
                  ):(
                    <NavLink to="/klassrummet" tag={Link} >
                    <i class="fas fa-book" style={{color: '#FF9A00', fontSize: "30px", marginLeft: 40}}></i>
                      <br></br>
                      <span style={{color: '#464B4B', fontSize: "14px"}}> Klassrummet</span>
                    </NavLink>
                  )
                }

              </NavItem> 
              <NavItem>

                {strNav === "/skrapskoj" || strNav === "/innehall/britta-hsr" || strNav === "/innehall/knepochknap" 
                || strNav.includes("/innehall/rolig-fakta")
                
                ? (
                  <NavLink to="/skrapskoj" tag={Link} style={active}>
                  <i class="fas fa-gamepad" style={{color: '#FF6600', fontSize: "30px", marginLeft: 20}}></i>
                    <br></br>
                    <span style={{color: '#464B4B', fontSize: "14px"}}> Skräpskoj</span>
                  </NavLink>
                  ):(
                    <NavLink to="/skrapskoj" tag={Link}>
                    <i class="fas fa-gamepad" style={{color: '#FF6600', fontSize: "30px", marginLeft: 20}}></i>
                      <br></br>
                      <span style={{color: '#464B4B', fontSize: "14px"}}> Skräpskoj</span>
                    </NavLink>
                  )
                }                

              </NavItem> 
              <NavItem>

              {strNav === "/innehall/plastexperimentet"
              ? (
                <NavLink to="/innehall/plastexperimentet" tag={Link} style={active}>
                <i class="fas fa-flask" style={{color: '#3b5b61', fontSize: "30px", marginLeft: 20}}></i>
                  <br></br>
                  <span style={{color: '#3b5b61', fontSize: "14px"}}> Plastexp.</span>
                </NavLink>
                ):(
                  <NavLink to="/innehall/plastexperimentet" tag={Link}>
                  <i class="fas fa-flask" style={{color: '#3b5b61', fontSize: "30px", marginLeft: 20}}></i>
                    <br></br>
                    <span style={{color: '#3b5b61', fontSize: "14px"}}> Plastexp.</span>
                  </NavLink>
                )
              }                

              </NavItem> 
              <NavItem>

                {strNav === "/innehall/om-skraplabbet" ? (
                  <NavLink to="/innehall/om-skraplabbet" tag={Link} style={active}>
                  <i class="fas fa-trash" style={{color: '#000', fontSize: "30px", marginLeft: 45}}></i>
                    <br></br>
                    <span style={{color: '#464B4B', fontSize: "14px"}}>Om Skräplabbet</span>
                  </NavLink>
                  ):(
                    <NavLink to="/innehall/om-skraplabbet" tag={Link}>
                    <i class="fas fa-trash" style={{color: '#000', fontSize: "30px", marginLeft: 45}}></i>
                      <br></br>
                      <span style={{color: '#464B4B', fontSize: "14px"}}>Om Skräplabbet</span>
                    </NavLink>
                  )
                }
                
              </NavItem> 

        
            </Nav>
          </Collapse>


          <Collapse
                className="justify-content-end"
                isOpen={collapseOpen}
                navbar
              > 
              
              
              <Nav navbar>

              
                <NavItem>
                  <img src={logoTop} height="80"></img>
                </NavItem>

              </Nav>
            </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default NavBar;
