import React from "react";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import { Row, Col } from "reactstrap";
import circle from "assets/img/right-arrow-circle.png";

const apiEndpoint = "https://skraplabbet.cdn.prismic.io/api/v2";
const accessToken = ""; // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken });

export default class LessonBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lessons: [],
      lessonsf: [],
      lessons46: [],
      lessonsFor: [],
    };
  }

  async componentDidMount() {
    await this.fetchLessons();
  }

  async fetchLessons() {

    let that = this
    Client.query(
        Prismic.Predicates.at("document.tags", ['Forskola'], { pageSize: 1, orderings : '[document.first_publication_date desc]' })
    ).then(function(response) {
      that.setState({lessonsf: response.results})       
    })

    Client.query(
      Prismic.Predicates.at("document.tags", ['F-3'], { pageSize: 1, orderings : '[document.first_publication_date desc]' })
    ).then(function(response) {
      that.setState({lessons46: response.results})       
    })

    Client.query(
      Prismic.Predicates.at("document.tags", ['4-6'], { pageSize: 1, orderings : '[document.first_publication_date desc]' })
    ).then(function(response) {
      console.log("forskola", response)
      that.setState({lessonsFor: response.results})       
    })


      

    // const response = await Client.query(
    //   Prismic.Predicates.at("document.type", "material"),
    //   { pageSize: 3, orderings : '[document.first_publication_date desc]' }
    // )
    //   //console.log("response", response)

    // this.setState({
    //   lessons: response.results,
    // });

    // Promise.resolve({
    //   id: selectedLesson.id,
    //   title: selectedLesson.data.title,
    //   intro: selectedLesson.data.intro,
    //   image: selectedLesson.data.lesson_image.url,
    //   aproximateTime: selectedLesson.data.aproximate_time
    //     ? selectedLesson.data.aproximate_time
    //     : "30",
    //   tags: selectedLesson.tags
    // })


    // const tags = ["Förskola", "F-3", "4-6", "7-9"];
    // let randomTags = [];

    // const randomTag0 = Math.floor(Math.random() * (tags.length - 1)) + 1
    // let randomTag1 = Math.floor(Math.random() * (tags.length - 1)) + 1
    
    // if (randomTag0 == randomTag1) {
    //   randomTag1 = Math.floor(Math.random() * (randomTags.length - 1)) + 1
    // }
    // randomTags.push(tags[randomTag0], tags[randomTag1]);

    // let selectedLessons = [];
    // selectedLessons = await this.fetchLessonsByTags(randomTags);
    // selectedLessons = selectedLessons.filter(Boolean);
    // this.setState({
    //   lessons: selectedLessons,
    // });
  }

  async fetchLessonsByTags(randomTags) {
    return Promise.all(
      randomTags.map((randomTag) => this.fetchLessonDetails(randomTag))
    );
  }

  async fetchLessonDetails(randomTag) {
   
    const response = await Client.query(
      Prismic.Predicates.at("document.tags", [randomTag]),
      { pageSize: 200 }
    )

    if (response.results.length > 0) {

      const lessons = response.results
      const totalLessons = lessons.length
      const lessonIndex = totalLessons === 1 ? 0 : Math.floor(Math.random() * (totalLessons - 1)) + 1
      const selectedLesson = lessons[lessonIndex]

      return Promise.resolve({
        id: selectedLesson.id,
        title: selectedLesson.data.title,
        intro: selectedLesson.data.intro,
        image: selectedLesson.data.lesson_image.url,
        aproximateTime: selectedLesson.data.aproximate_time
          ? selectedLesson.data.aproximate_time
          : "30",
        tags: selectedLesson.tags
      })

    }
  }

  renameTag(tagIn) {
    tagIn = tagIn.replace("Forskola", "Förskola")
    tagIn = tagIn.replace("Skrapplockardagarna", "Skräpplockardagarna")
    tagIn = tagIn.replace("Skrapmatning", "Skräpmatning")
    tagIn = tagIn.replace("Fakta om skrap", "Fakta om skräp")
    tagIn = tagIn.replace("Allemansratten", "Allemansrätten")
    tagIn = tagIn.replace("Skrap i havet", "Skräp i havet")
    tagIn = tagIn.replace("Sang och musik", "Sång och musik")
    tagIn = tagIn.replace("Cirkular ekonomi", "Cirkulär ekonomi")
    return tagIn
  }

  render() {

    let that = this 
    console.log("render");

    const lessons = this.state.lessons;

    const lessonsf = this.state.lessonsf;
    const lessons46 = this.state.lessons46;
    const lessonsFor = this.state.lessonsFor;
    
    return (
      <div className="homeBox" style={{ marginTop: 30 }}>
        <Row>
          <Col>
            <h3>Nytt i lärarummet</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut rutrum
              leo non orci porta luctus facilisis eget tortor. Nullam placerat
              accumsan metus.
            </p> */}
            {lessonsf.slice(0,1).map((lesson) =>                        
            (            
              <>
                <hr></hr>
                <Row>
                  <Col style={{ maxWidth: "110px" }} sm="12">
                    <div class="circular">
                      <img src={lesson.data.lesson_image.url +"&h=200"}></img>
                    </div>
                  </Col>
                  <Col>

                  <a href={'/topic/'+ lesson.id} style={{ fontSize: 15, color: "#000" }}>
                    <h3 className="homeTitle4" style={{ marginTop: 0 }}>
                      {lesson?.data?.title && lesson?.data?.title[0]?.text}
                    </h3>
                  </a>

                    <p>{lesson.data.intro && lesson?.data.intro[0]?.text}</p>

                      <div style={{marginTop: 20}}>
                        {
                        
                        lesson?.tags.map(function (tag) 
                        {
                          let lOut = tag
                          if (lOut.startsWith("S_")) {
                            lOut = tag.substring(2)
                          }
                          return (<span style={{
                            backgroundColor: '#fff',
                            fontSize:10,
                            padding: 5,
                            borderRadius: 5,
                            marginRight: 10
                          }}>{that.renameTag(lOut)}</span>)
                        })}
                      </div>

                    {/* <p>
                      <small>Uppskattad tid: {lesson.aproximateTime} min</small>
                    </p> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{ float: "right", fontWeight: 900, fontSize: 15 }}
                    >
                      <a href={'/topic/'+ lesson.id} style={{ color: "#000" }}>
                        Till lektionen{" "}
                        <i
                          class="fas fa-chevron-circle-right"
                          style={{ color: "#000", fontSize: "15px" }}
                        ></i>{" "}
                      </a>
                    </div>
                  </Col>
                </Row>
              </>
            ))}


            {lessons46.slice(0,1).map((lesson) =>                        
            (            
              <>
                <hr></hr>
                <Row>
                  <Col style={{ maxWidth: "110px" }} sm="12">
                    <div class="circular">
                      <img src={lesson.data.lesson_image.url +"&h=200"}></img>
                    </div>
                  </Col>
                  <Col>

                  <a href={'/topic/'+ lesson.id} style={{ fontSize: 15, color: "#000" }}>
                    <h3 className="homeTitle4" style={{ marginTop: 0 }}>
                      {lesson?.data?.title && lesson?.data?.title[0]?.text}
                    </h3>
                  </a>

                    <p>{lesson.data.intro && lesson?.data.intro[0]?.text}</p>

                      <div style={{marginTop: 20}}>
                        {
                        
                        lesson?.tags.map(function (tag) 
                        {
                          let lOut = tag
                          if (lOut.startsWith("S_")) {
                            lOut = tag.substring(2)
                          }
                          return (<span style={{
                            backgroundColor: '#fff',
                            fontSize:10,
                            padding: 5,
                            borderRadius: 5,
                            marginRight: 10
                          }}>{that.renameTag(lOut)}</span>)
                        })}
                      </div>

                    {/* <p>
                      <small>Uppskattad tid: {lesson.aproximateTime} min</small>
                    </p> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{ float: "right", fontWeight: 900, fontSize: 15 }}
                    >
                      <a href={'/topic/'+ lesson.id} style={{ color: "#000" }}>
                        Till lektionen{" "}
                        <i
                          class="fas fa-chevron-circle-right"
                          style={{ color: "#000", fontSize: "15px" }}
                        ></i>{" "}
                      </a>
                    </div>
                  </Col>
                </Row>
              </>
            ))}

            {lessonsFor.slice(0,1).map((lesson) =>                        
            (            
              <>
                <hr></hr>
                <Row>
                  <Col style={{ maxWidth: "110px" }} sm="12">
                    <div class="circular">
                      <img src={lesson.data.lesson_image.url +"&rect=0,0,667,667"}></img>
                    </div>
                  </Col>
                  <Col>

                  <a href={'/topic/'+ lesson.id} style={{ fontSize: 15, color: "#000" }}>
                    <h3 className="homeTitle4" style={{ marginTop: 0 }}>
                      {lesson?.data?.title && lesson?.data?.title[0]?.text}
                    </h3>
                  </a>

                    <p>{lesson.data.intro && lesson?.data.intro[0]?.text}</p>

                      <div style={{marginTop: 20}}>
                        {
                        
                        lesson?.tags.map(function (tag) 
                        {
                          let lOut = tag
                          if (lOut.startsWith("S_")) {
                            lOut = tag.substring(2)
                          }
                          return (<span style={{
                            backgroundColor: '#fff',
                            fontSize:10,
                            padding: 5,
                            borderRadius: 5,
                            marginRight: 10
                          }}>{that.renameTag(lOut)}</span>)
                        })}
                      </div>

                    {/* <p>
                      <small>Uppskattad tid: {lesson.aproximateTime} min</small>
                    </p> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{ float: "right", fontWeight: 900, fontSize: 15 }}
                    >
                      <a href={'/topic/'+ lesson.id} style={{ color: "#000" }}>
                        Till lektionen{" "}
                        <i
                          class="fas fa-chevron-circle-right"
                          style={{ color: "#000", fontSize: "15px" }}
                        ></i>{" "}
                      </a>
                    </div>
                  </Col>
                </Row>
              </>
            ))}

          </Col>
        </Row>
      </div>
    );
  }
}
