import React from "react";
import {Container, Row, Col,  Card, CardBody, CardTitle, CardText, Button} from "reactstrap";
import { RichText } from 'prismic-reactjs'
import Prismic from 'prismic-javascript'
import NavBar from "components/Navbars/NavBar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DarkFooter from "components/Footers/DarkFooter"
import teacher from 'assets/img/teacher.png'
import kids from 'assets/img/kids.png'
import young from 'assets/img/young.png'
import circle from 'assets/img/right-arrow-circle.png'
import smallwhite from 'assets/img/small-white.png'
import brittagul from 'assets/img/brittagul.png'
import brittaorange from 'assets/img/brittaorange.png' 
import LessonBox from './LessonBox'
import GoingOnBox from './GoingOnBox'
import SkrapiBox from './SkrapiBox'
import SkojBox from './SkojBox'
import Unity, { UnityContext } from "react-unity-webgl";
import skrapround from 'assets/img/skrapround.jpg' 

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
 
const Client = Prismic.client(apiEndpoint, { accessToken })

class LandingPage extends React.Component {

  constructor(props){

    super(props);

    this.state = {
      doc: null,
      page: null,
      itemsInFolder: 0,
    }
    
    this.fetchPage()
    this.getLessonsByTag()

    this.unityContext = new UnityContext({
      loaderUrl: "games/build/myunityapp.loader.js",
      dataUrl: "build/myunityapp.data",
      frameworkUrl: "build/myunityapp.framework.js",
      codeUrl: "build/myunityapp.wasm"
    });
    
  }


  async getLessonsByTag(){

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at("document.tags", ['Highlight'])
    ).then(function(response) {
      that.setState({ selectedLessons: response })
    })

  }

  async fetchPage() {

    let that = this
    const response = await Client.getByUID('content_page', 'home').then(function(document) {
      if (document) {
        that.setState({ page: document });
      } else {
        that.setState({ page: !document });
      }
    })
  
  }

  render(){

    const { page, selectedLessons } = this.state
    let pageContent = null

    if(page){

      pageContent = page

      console.log("pageContent", pageContent)

      return (
        <>
          <NavBar uid={'home'}/>
    
          <div className="wrapper">
         
              <LandingPageHeader uid={'home'}/>

              <div className="first-row">

                <Container> <div className="homeBoxPlast">
                  <Row>
                    <Col sm="12" lg="2">
                              <img src="https://hsr.se/sites/default/files/styles/text_and_image_tablet_portrait_400xauto_/public/2020-12/plast.png.jpg?itok=ZJ0z-Xie" width="150"></img>       
                     </Col>
                    <Col sm="12" lg="10">
                     
                        <h3 className="homeTitlePlast" style={{marginBottom:10}}>Var med och medborgarforska för miljön!</h3>  
                                              
                        <p className="whiteText">
                        För att kunna lösa problemen och minska plastskräp i naturen behöver vi förstå mer om var, när och varför plast hamnar där. I Plastexperimentet får skolelever i årskurs 4-9 och gymnasiet vara del av ett forskningsprojekt och bidra till att kartlägga hur mycket plastskräp det finns i olika naturtyper i Sverige.
                        </p>
                        <div
                          style={{
                            float: "right",
                            fontWeight: 900,
                            fontSize: "0.9em",
                          }}
                        >
                          <a href={'/innehall/plastexperimentet'} style={{fontSize: 15, color: '#fff'}}>
                            Ta mig dit {" "}
                            <i class="fas fa-arrow-circle-right" 
                  style={{color: '#fff', fontSize: "15px"
                  }}></i>    
                          </a>
                        </div>
                    </Col>
                  </Row> </div>
                  <Row>
                    <Col sm="12" lg="4">
                      <div className="homeBox">
                      
                        <Row>
                          <Col>
                            <h3 className="homeTitle1" style={{marginBottom:10}}>Lärarrummet</h3>                        
                          </Col>                        
                        </Row>
                        <Row>
                          <Col style={{maxWidth: 120}}>
                              <img src="https://skraplabbet.se/static/media/teacher.a71c2091.png" width="150"></img>       
                          </Col>
                          <Col>                          
                            <p>
                              {pageContent.data?.intro_larare[0].text}
                              </p>

                              <div
                                style={{
                                  float: "right",
                                  fontWeight: 900,
                                  fontSize: "0.9em",
                                }}
                              >
                                <a href={'/teacher'} style={{fontSize: 15, color: '#000'}}>
                                  Ta mig dit {" "}
                                  <i class="fas fa-arrow-circle-right" 
                        style={{color: '#000', fontSize: "15px"
                        }}></i>    
                                </a>
                              </div>
                                                
                          </Col>
                        </Row>
                      
                      </div>                    
                    </Col>
                    <Col sm="12" lg="4">          
                      <div className="homeBox">
                        <Row>
                          <Col>
                            <h3 className="homeTitle2" style={{marginBottom:10}}>Klassrummet</h3>
                          </Col>
                          
                        </Row>         
                        <Row>
                          <Col style={{maxWidth: 120}}>
                              <img src="https://skraplabbet.se/static/media/kids.93163770.png" width="150"></img>       
                          </Col>
                          <Col>                          
                            <p>
                            {pageContent.data?.intro_klassrummet[0].text}
                              </p>         

                              <div
                                style={{
                                  float: "right",
                                  fontWeight: 900,
                                  fontSize: "0.9em",
                                }}
                              >
                                <a href={'/klassrummet'} style={{fontSize: 15, color: '#000'}}>
                                  Ta mig dit {" "}
                                  <i class="fas fa-arrow-circle-right" 
                        style={{color: '#000', fontSize: "15px"
                        }}></i>    
                                </a>
                              </div>                 
                          </Col>
                        </Row>     
                      </div>
                    </Col>
                    <Col sm="12" lg="4">
                      <div className="homeBox">
                        <Row>
                            <Col>
                            <h3 className="homeTitle3"  style={{marginBottom:10}}>Skräpskoj</h3>
                            </Col>                         
                        </Row>    
                        <Row>
                            <Col style={{maxWidth: 120}}>
                            <div class="circular">
                              <img src={skrapround} width="150"></img>    
                              </div>   
                          </Col>
                            <Col>                              
                              <p>
                              {pageContent.data?.intro_skoj[0].text}
                                </p>                

                                <div
                                style={{
                                  float: "right",
                                  fontWeight: 900,
                                  fontSize: "0.9em",
                                }}
                              >
                                <a href={'/skrapskoj'} style={{fontSize: 15, color: '#000'}}>
                                  Ta mig dit {" "}
                                  <i class="fas fa-arrow-circle-right" 
                        style={{color: '#000', fontSize: "15px"
                        }}></i>    
                                </a>
                              </div>          
                            </Col>
                        </Row>
                      </div>      
                    </Col>  
                  </Row>
                </Container>
              </div>
              
              <Container>
                <Row>
                  <Col sm="12" lg="6">
                    <h2 style={{marginBottom: 20, marginTop:20}}>Hållbarhet på schemat</h2>
                  </Col>
                </Row>
              </Container>


              <Container>
                <Row>
                  <Col sm="12" lg="6">

                    <GoingOnBox />
                    <LessonBox /> 

                  </Col>
                  <Col>     


                    <div className="homeBoxChat" style={{marginTop: 12}}>
                     
                      <Row>
                         <Col>                        
                         <h3 className="homeTitle5">Grön flagg</h3>  
                           <p style={{color: '#fff'}}>
                           Utbildningsprogrammet Grön Flagg består av sex steg som över ett läsår hjälper förskolor och skolor att arbeta mer strukturerat och engagerat med processer för hållbar utveckling. Grön Flagg-metoden är flexibel och lätt att anpassa och omsätta direkt, oavsett verksamhetens egna förutsättningar.
                            </p>                          
                         </Col>
                         <Col style={{maxWidth: 100}}>
                          <img src={"https://hsr.se/sites/default/files/2021-01/191003_Gr%C3%B6nFlagg_logo_m%C3%B6rkgr%C3%B6n.png"} style={{float: 'right'}} style={{maxWidth: 80}}></img>
                        </Col>        
                       </Row>     
                       <Row>
                          <Col>
  
                            <div style={{ color:'white', float: 'right', fontWeight: 900,  fontSize: 15 }}> 
                             <a href="https://hsr.se/gronflagg" style={{color:'white'}} target={"_blank"}>Mer om Grön flagg <i class="fas fa-chevron-circle-right" 
                      style={{color: '#000', fontSize: "15px"
                      }}></i></a>     
                            </div>                            
                          </Col>                      
                        </Row>
                    </div>
                    
                    <SkojBox></SkojBox>



                    <SkrapiBox></SkrapiBox>


                  </Col>
                 
                </Row>
          
                <br></br><br></br><br></br><br></br>

              </Container>             
        
             <DarkFooter/>

          </div>
        </>
      )

    }
    else{
      return null
    }
  }

  
}

export default LandingPage;
