import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input, Form, FormGroup, Card, CardBody, CardTitle, CardText, Label
} from "reactstrap";
import { connect } from "react-redux"
import Prismic from 'prismic-javascript'
import NavBarPreSchool from "components/Navbars/NavBarPreSchool"
import LandingPageClassRoom from "components/Headers/LandingPageClassRoom";
import DarkFooter from "components/Footers/DarkFooter"
import { RichText } from 'prismic-reactjs'
import sea_movie from 'assets/img/sea_movie.png'
import fish_movie from 'assets/img/fish_movie.png'
import dog_movie from 'assets/img/dog_movie.png'
import button_shark from 'assets/img/button_breakdown.png'
import button_skate from 'assets/img/button_recycle.png'
import button_gronan from 'assets/img/button_garbage.png'
import button_bunny from 'assets/img/button_bunny.png'
import Coverflow from 'react-coverflow';
import SkrapipediaMaster from "components/SkrapipediaMasterPreSchool"
import posed from 'react-pose';
import { msgChatMessageRequest } from "../store/actions";
import Animate from 'react-smooth';
import britta from 'assets/img/britta.png'
import ModalVideo from 'react-modal-video'
import Slider from "react-slick"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
 
const steps = [{
    style: {
      opacity: 0,
    },
    duration: 1000,
    }, 
    {
    style: {
      opacity: 1,
    },
    duration: 1000,
  }

];

const Box = posed.div({
  hidden: { opacity: 0, height: '0px' },
  visible: { opacity: 1, height: '350px' }
})

const BoxHide = posed.div({
  hidden: { opacity: 0},
  visible: { opacity: 1 }
})

const BoxMaster = posed.div({
  hidden: { opacity: 1 },
  visible: { opacity: 0 }
})

const apiEndpoint = 'https://skraplabbet.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
const Client = Prismic.client(apiEndpoint, { accessToken })

class HomeClassRoom extends React.Component {


  constructor(props){

    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
    this.state = {
      doc: null,
      page: null,
      selectedLessons : null,
      itemsInFolder: 0,
      selectedExpert: 0,
      isVisible: true,
      textToBeSent: "",
      isOpen: false,
      videos: [],
      history: []
    
    }


  }


  async fetchPage() {

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at('document.id', 'XkMQeRMAACIAqn7I')
    ).then(function(response) {
      if(response)
        that.setState({ page: response });
    })
  
  }

  async fetchVideos() {

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at("document.tags", ['Förskola video'])
    ).then(function(response) {
      if(response)
        that.setState({ videos: response });
    })
  
  }

  async fetchHistories() {

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at('document.type', 'saga')
    ).then(function(response) {
      if(response)
        that.setState({ history: response });
    })
  
  }

  async getLessonsByTag(){

    let that = this
    const r = await Client.query(
      Prismic.Predicates.at("document.tags", ['Highlight'])
    ).then(function(response) {
      that.setState({ selectedLessons: response })
    })

  }

  componentDidMount(){
    
    this.fetchPage()
    this.getLessonsByTag()
    this.fetchVideos()
    this.fetchHistories()

    let itemsInFolder = localStorage.getItem("lessons")
    if(itemsInFolder){
      let res = itemsInFolder.split(",");
      this.state.itemsInFolder = res.length
    }
    
  }

  handleExpertSelection(levelExpert){
   
    this.setState({ selectedExpert: levelExpert})
    this.setState({ isVisible: !this.state.isVisible })
   
  }

  componentDidUpdate() {
    // window.scrollTo(0, document.body.scrollHeight);
  }

  handleChange(e) {
    this.setState({ textToBeSent: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { textToBeSent } = this.state;
    const { sendMessage } = this.props;
    if (textToBeSent.trim() === "") {
      //alert("Empty is not allowed! ");
      return;
    }
    sendMessage(textToBeSent);
    this.setState({ textToBeSent: " " });

    window.setTimeout(function() {
      var elem = document.getElementById('data');
      elem.scrollTop = elem.scrollHeight;
    }, 1000);

  }

  render(){
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            initialSlide: 0
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        }
      ]
    };
    const { messages, onProcess } = this.props;
    const { page, selectedExpert , itemsInFolder, textToBeSent, videos, history} = this.state
    
    if(page && videos.results && history.results){
      
      const videoContent = videos.results[0].data
      const historyContent = history.results

      console.log("videoContent", videos.results[0].data)
      console.log("historyContent", historyContent)
      return (
        <>
          <NavBarPreSchool itemsInFolder={itemsInFolder} />
    
          <div className="wrapper">
                   
             
              <div className="bg-red-top classroom">
                <Container>
                
                  <Row>
                    <Col style={{textAlign: 'center'}} lg="12">
                      <h2>Filmer</h2>
                    </Col>
                  </Row>

                  <Row style={{paddingBottom: 100}}>                   
                    <Col sm={12} lg={12}>
                 
                    <Slider {...settings}>

                        {
                        // "https://www.youtube.com/embed/U_ogaXMUhWY"
                        videoContent && videoContent.video.map(function(item){
                            console.log(">>>>", item)
                            return(
                             <div> 
                               <div class="video-wrapper">
                                <iframe width="100%" height="480" src={`https://www.youtube.com/embed/${item.videoid[0].text}`}  />       
                                </div>
                             </div>         
                            )
                          }
                        )
                        }
                    </Slider>
                   
                    <div>
                                            
                  </div>
                    

                   
                    </Col>
                  </Row>
                </Container>
              </div>


              <div className="bg-green classroom" name="master">
                <Container>

                  <Row>
                    <Col style={{textAlign: 'center'}} lg="12">
                      <h2>Är du Skräpipedia Master?</h2>
                    </Col>
                    <Col style={{textAlign: 'center'}} lg="12">
                      <p style={{fontSize: "20px", color: "white"}}>Kampen mot klockan och dina vänner startar här och nu! <br></br>Är du redo? <br></br><br></br>
                      </p>

                      <BoxHide className="box" pose={this.state.isVisible ? 'visible' : 'hidden'} >
                      <p style={{fontSize: "20px", color: "white"}}> <b style={{fontWeight: 900}}>Välj expertisområde nedan!</b></p>
                      </BoxHide>
                  
                    </Col>
                  </Row>
                  
                  <Box className="box" pose={this.state.isVisible ? 'visible' : 'hidden'} >
                    <Row>

                      <Col style={{textAlign: 'center'}} lg="6"><img src={button_gronan} alt='Staden' style={{marginBottom: "10px", cursor: 'pointer', width:200}}  onClick={() => this.handleExpertSelection(1)} /><br></br><h4>Vanligaste Skräpet</h4></Col>

                      <Col style={{textAlign: 'center'}}  lg="6"><img src={button_shark} alt='Havet' data-action="#" style={{marginBottom: "10px", cursor: 'pointer',width:200}} onClick={() => this.handleExpertSelection(2)}/><br></br><h4>Nedbrytningstider</h4></Col>

                      {/* <Col style={{textAlign: 'center'}}  lg="4"><img src={button_skate} alt='Människan' data-action="#" style={{marginBottom: "10px", cursor: 'pointer',width:200}} onClick={() => this.handleExpertSelection(3)}/><br></br><h4>Hur ska det sorteras?</h4></Col> */}

                      {/* <Col style={{textAlign: 'center'}}  lg="3"><img src={button_bunny} alt='Naturen' data-action="#" style={{marginBottom: "10px", cursor: 'pointer'}} onClick={() => this.handleExpertSelection(4)}/><br></br><h4>Natur</h4></Col> */}

                    </Row>
                  </Box>

                  

                
                </Container>
                
                <BoxMaster className="box" pose={this.state.isVisible ? 'visible' : 'hidden'} >
                  <SkrapipediaMaster selectedExpert={selectedExpert}></SkrapipediaMaster>
                </BoxMaster>

              </div>

              <div className="bg-britta classroom">
                <Container>
                  <Row>
                    <Col lg="6">

                      
                      <h2>Fråga Britta</h2>
                      <p>Säg hej till Britta! Hon är vår nya skräpexpert som direkt kan ge svar på dina frågor om skräp. Sakta men säkert kommer hon att kunna svara på alla dina frågor, men just den här veckan svarar Britta på frågor om nedbrytningstider. Testa Britta och se vad hon kan! </p>     
                      
                     
                      <br/><br/>
                    </Col>
                    <Col lg="6">
                      <div style={chat} id="data" class="chat">

                      {messages.map(({ text, sender, isErrorMessage }, index) => (

                        sender === "Bot" ? <Animate steps={steps}>

                            <Row>
                              <Col xs="10" sm="10">
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col lg="3"><img src={britta} height={60}/></Col>
                                      <Col>{text}</Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xs="2" sm="2"></Col>

                            </Row>

                          </Animate> : 
                          <Animate steps={steps}>

                            <Row>

                              <Col xs="2" sm="2"></Col>
                              <Col xs="10" sm="10">
                                <Card>
                                  <CardBody>
                                    <Row>
                                      <Col lg="2"><i class="gg-smile"></i></Col>
                                      <Col>Du: {text}</Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>

                          
                          </Animate>
                        
                        
                      ))}

                      </div>

                      <form onSubmit={this.handleSubmit}>
                        <Input
                          ref={input => input && input.focus()}
                          type="text"
                          value={onProcess ? "Väntar på svar..." : textToBeSent}
                          onChange={this.handleChange}
                          placeholder="Skriv Hej och tryck på enter!"
                          className="chatBox"
                          disabled={onProcess}
                        />
                      </form>
                    </Col>
                    
                  </Row>

                 
                </Container>
              </div>

              <div className="bg-rapporten" style={{marginBottom: 20}}>
                <Container>
                                
                  <Row>
                    <Col lg="6">              
                    <h2>Sagor/Bildspel</h2>     
                      <p>
                      Här samlar vi sagor och berättelser i from av bildspel för er att läsa tillsammans. 
                      </p>
                      <p>
                        Blädra och klicka på den som lockar för att läsa hela berättelsen.
                      </p>
                
                    </Col>
                    <Col lg="6">                   
                    
                     
                      <Slider {...settings}>
                      {                     
                      historyContent && historyContent.map(function(item){
                          console.log(">>>> saga", item)
                          return(
                          <div> 
                            <div class="video-wrapper">
                                <h4>{item.data.title[0].text}</h4>
                                <a href={'/story/'+ item.id}><img src={item.data.image.url} class="rounded"/></a>
                              </div>
                          </div>         
                          )
                        }
                      )
                      }
                      </Slider>

                    </Col>
                    
                  </Row>
                </Container>
              </div>

              <div className="bg-skrapipedia classroom  no-print">
                <Container>
                  <Row>
                    <Col lg="6">
                      <h2>Skräpipedia</h2>
                      <p>
                      Här i Skräpipedia har vi samlat vår senaste kunskap om nedskräpning. Kunskapen får vi från forskare och experter och för att vi hela tiden mäter skräp på olika platser. Hoppa in och läs och lär dig mer!  
                      </p>
                      <a href="/contentpage/skrapipedia/skrapipedia" className="btn-cta-classroom">Ta mig dit</a>
                    </Col>
                    <Col lg="6">
                      <img src="https://images.prismic.io/skraplabbet/ee2f2659-7a9c-4579-984a-35d16b5ea418_books.jpg?auto=compress,format" alt="" class="rounded"/>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* <div className="bg-skrapipedia classroom">
                <Container>
                  <Row>
                    <Col lg="6">
                      <h2>Skräpipedia</h2>
                      <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id elit at nisi viverra tempus. Sed vehicula ut dui non condimentum. Donec ut dictum est. Nulla ut metus id dolor tincidunt iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ac neque pulvinar, consequat nulla non, consectetur nulla. 
                      </p>
                      <a href="/contentpage/skrapipedia/skrapipedia" className="btn-cta-classroom">Ta mig dit</a>
                    </Col>
                    <Col lg="6">
                      <img src="https://images.prismic.io/skraplabbet/ee2f2659-7a9c-4579-984a-35d16b5ea418_books.jpg?auto=compress,format" alt="" class="rounded"/>
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className="bg-rapporten">
                <Container>
                  <Row>
                    
                    <Col lg="6">
                      <img src="https://images.prismic.io/skraplabbet/943edbc3-307a-4416-aa07-c8a4d0485202_rapport.png?auto=compress,format" alt="" class="rounded"/>
                    </Col>
                    <Col lg="6">
                      <h2 className="blackText headline">Lilla skräprapporten 2020</h2>
                      <p className="blackText">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id elit at nisi viverra tempus. Sed vehicula ut dui non condimentum. Donec ut dictum est. Nulla ut metus id dolor tincidunt iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ac neque pulvinar, consequat nulla non, consectetur nulla. 
                      </p>
                      <a href="/contentpage/lilla-skraprapporten-2020/Skräprapporten%202020" className="btn-cta-classroom">Läs Lilla skräprapporten</a>
                    </Col>
                  </Row>
                </Container>
              </div>

              <div className="bg-inspiration">
                <Container>
                  <Row>
                    <Col lg="6">
                      <h2 className="whiteText headline">Vad kan du göra?</h2>
                     
                     <h4 className="whiteText">Massor!</h4>
                     <p className="whiteText ">
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id elit at nisi viverra tempus. Sed vehicula ut dui non condimentum. Donec ut dictum est. Nulla ut metus id dolor tincidunt iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ac neque pulvinar, consequat nulla non, consectetur nulla. 
                      </p>
                      <a href="/contentpage/inspiration/inspiration" className="btn-cta-classroom">Ta mig till inspiration</a>
                    </Col>
                    <Col lg="6">
                    <img src="https://images.prismic.io/skraplabbet/ee3ccf46-1a9e-4b65-a7fa-cc0e2b2495a8_change.png?auto=compress,format" alt="" class="rounded"/>
                    </Col>
                    
                  </Row>
                </Container>
              </div> */}

             <DarkFooter />
          </div>
        </>
      )

    }
    else{
      return null
    }
  }

  
}

const chat ={
  height: '300px',
  overflow: 'scroll',

}
const headingStyle = {
 
}
const listStyle = {
 
};
const formPosStyle = {
  backgroundColor: "white"
};


const mapStateToProps = state => state;
const mapActionToProps = { sendMessage: msgChatMessageRequest };

export default connect(mapStateToProps, mapActionToProps)(HomeClassRoom);

// export default HomeClassRoom;
